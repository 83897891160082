import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [language, setLanguage] = useState("hr");

  const [apartments, setApartments] = useState([]);
  const [news, setNews] = useState([]);

  return (
    <AppContext.Provider
      value={{
        apartments,
        setApartments,
        news,
        setNews,
        language,
        setLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
