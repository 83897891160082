// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Uvezi ili definiraj prijevode
const resources = {
  en: {
    translation: {
      Price: "Price",
      Area: "Area",
      Home: "Home",
      "About Us": "About Us",
      Appartments: "Appartments",
      Blog: "Blog",
      Project: "Project",
      Contact: "Contact",
      Email: "Email",
      Phone: "Phone",
      News: "News",
      "ENTRANCE/HALLWAY": "ENTRANCE/HALLWAY",
      "LIVING AREA": "LIVING AREA",
      KITCHEN: "KITCHEN",
      BATHROOM: "BATHROOM",
      BEDROOM: "BEDROOM",
      "BEDROOM 1": "BEDROOM 1",
      "BEDROOM 2": "BEDROOM 2",
      "COVERED TERRACE": "COVERED TERRACE",
      "OPEN TERRACE": "OPEN TERRACE",
      "GREEN AREA": "GREEN AREA",
      "2 PARKING SPOTS": "2 PARKING SPOTS",
      "Look at news about building process.":
        "Look at news about building process.",
      "Looking for an apartment in an attractive location on the coast?":
        "Looking for an apartment in an attractive location on the coast?",
      "Read more": "Read more",
      "Change language": "Promijeni jezik", // obrnuta logika
      "All appartments": "All appartments",
      "About Project": "About Project",
      "Looking for an apartment in an attractive location on":
        "Looking for an apartment in an attractive location on",
      "Biograd Riviera": "Biograd Riviera",
      "Dalmaland Aquapark is a great place for family fun. It is located near Biograd na Moru.":
        "Dalmaland Aquapark is a great place for family fun. It is located near Biograd na Moru.",
      "Apartments near the Soline beach are a great choice for a vacation. The beach is almost perfect for the family.":
        "Apartments near the Soline beach are a great choice for a vacation. The beach is almost perfect for the family.",
      "Biograd na Moru is a city located on the Adriatic Sea. It is known for its rich history and beautiful beaches.":
        "Biograd na Moru is a city located on the Adriatic Sea. It is known for its rich history and beautiful beaches.",
      "Click here to see 2. apartment on 2. floor":
        "Click here to see 2. apartment on 2. floor",
      "Biograd na Moru": "Biograd na Moru",
      "Click here to see 2. apartment on 2. floor":
        "Click here to see 2. apartment on 2. floor",
      "Project of constructing 3 buildings near the sea. Each building has 6 apartments.":
        "Project of constructing 3 buildings near the sea. Each building has 6 apartments.",
      "Our Address": "Our Address",
      "Atractive places near the apartments:":
        "Atractive places near the apartments:",
      "Town Biograd na Moru": "Town Biograd na Moru",
      "Dalmaland Aquapark ": "Dalmaland Aquapark ",
      "Soline Beach": "Soline Beach",
      "Project of constructing 3 buildings near the sea. Each building has 6 apartments.":
        "Project of constructing 3 buildings near the sea. Each building has 6 apartments.",
      "A new residential project in Biograd na Moru features the construction of three buildings, each offering a unique living experience. Situated in the heart of the city, these buildings are conveniently close to the main attractions, including Soline Beach and the Dalmaland Aquapark, making them ideal for both relaxation and entertainment. Each of the three buildings spans three floors and houses six apartments, ensuring a sense of community and exclusivity. The apartments are available at a promotional price of 1999 EUR per square meter, providing an excellent investment opportunity. This project not only offers luxurious living spaces but also benefits from its prime location, blending urban convenience with natural beauty. With their modern design and strategic positioning, these buildings are set to become a coveted address in Biograd na Moru.":
        "A new residential project in Biograd na Moru features the construction of three buildings, each offering a unique living experience. Situated in the heart of the city, these buildings are conveniently close to the main attractions, including Soline Beach and the Dalmaland Aquapark, making them ideal for both relaxation and entertainment. Each of the three buildings spans three floors and houses six apartments, ensuring a sense of community and exclusivity. The apartments are available at a promotional price of 1999 EUR per square meter, providing an excellent investment opportunity. This project not only offers luxurious living spaces but also benefits from its prime location, blending urban convenience with natural beauty. With their modern design and strategic positioning, these buildings are set to become a coveted address in Biograd na Moru.",
      "Looking for an apartment in an attractive location on the coast?":
        "Looking for an apartment in an attractive location on the coast?",
      "Atractive places near the apartments:":
        "Atractive places near the apartments:",
      "The combination of modern design, Middle Dalmatian architecture, and superior construction quality makes this residential building in a peaceful part of Biograd particularly appealing. The property is equally distant (a 10-minute walk) from three key locations: the center of Biograd; Soline, the most beautiful beach in this part of Dalmatia; and Dalmaland, the most attractive amusement/water park in all of Dalmatia.":
        "The combination of modern design, Middle Dalmatian architecture, and superior construction quality makes this residential building in a peaceful part of Biograd particularly appealing. The property is equally distant (a 10-minute walk) from three key locations: the center of Biograd; Soline, the most beautiful beach in this part of Dalmatia; and Dalmaland, the most attractive amusement/water park in all of Dalmatia. ",
      "Click to see more": "Click to see more",
      "Biograd na Moru is a city located on the Adriatic Sea. It is known for its rich history and beautiful beaches.":
        "Biograd na Moru is a city located on the Adriatic Sea. It is known for its rich history and beautiful beaches.",
      "Apartments near the Soline beach are a great choice for a vacation. The beach is almost perfect for the family.":
        "Apartments near the Soline beach are a great choice for a vacation. The beach is almost perfect for the family.",
      "Dalmaland Aquapark is a great place for family fun. It is located near Biograd na Moru.":
        "Dalmaland Aquapark is a great place for family fun. It is located near Biograd na Moru.",
      "Beautiful town on the Adriatic Sea":
        "Beautiful town on the Adriatic Sea",
      "The most beautiful beach in Dalmatia":
        "The most beautiful beach in Dalmatia",
      "The most attractive amusement/water park in all of Dalmatia":
        "The most attractive amusement/water park in all of Dalmatia",
      "Surrounding Places": "Surrounding Places",
      "Here are some of the most beautiful places in the town. ":
        "Here are some of the most beautiful places in the town. ",
      "Follow us on social media": "Follow us on social media",
      "€ discount price": "€ discount price",
      "Three-room apartments on the first floor.":
        "Three-room apartments on the first floors.",
      "€ for apartments (2) on ground floor.":
        "€ for apartments (2) on ground floor.",
      "Attractive garden, ideal for a relaxation zone: installing a swimming pool or jacuzzi.":
        "Attractive garden, ideal for a relaxation zone: installing a swimming pool or jacuzzi.",
      " parking spots": " parking spots",
      "Every apartment has 2 parking spots.":
        "Every apartment has 2 parking spots.",
      "Click here to see apartments in this building.":
        "Click here to see apartments in this building.",
      "The other two buildings are under construction.":
        "The other two buildings are under construction.",
      "Interested in buying?": "Interested in buying?",
      "Fill out the contact form below or contact us through the contact information.":
        "Fill out the contact form below or contact us through the contact information.",
      "Send message": "Send message",
      "Contact us.": "Contact us.",
      Message: "Message",
      "Phone number": "Phone number",
      "Last Name": "Last Name",
      "First Name": "First Name",
      "Two Parking Spaces": "Two Parking Spaces",
      "Two parking spaces for your car": "Two parking spaces for your car",
      "Relax in the jacuzzi in the garden":
        "Relax in the jacuzzi in the garden",
      "Jacuzzi in the garden": "Jacuzzi in the garden",
      "Quiet neighborhood": "Quiet neighborhood",
      "Great for families": "Great for families",
      Exterior: "Exterior",
      "Take a look at the exterior of our apartments.":
        "Take a look at the exterior of our apartments.",
      "Take a look at the surrounding places":
        "Take a look at the surrounding places",
      Back: "Back",
      "Golf Club in Biograd na Moru": "Golf Club in Biograd na Moru",
      "Golf Club": "Golf Club",
      "Dalmaland Aquapark ": "Dalmaland Aquapark ",
      "The most attractive amusement/water park in all of Dalmatia":
        "The most attractive amusement/water park in all of Dalmatia",
      "The most beautiful beach in Dalmatia":
        "The most beautiful beach in Dalmatia",
      "Soline Beach": "Soline Beach",
      "Beautiful town on the Adriatic Sea":
        "Beautiful town on the Adriatic Sea",
      "Town Biograd na Moru": "Town Biograd na Moru",
      "Town Biograd na Moru": "Town Biograd na Moru",
      "Contact us.": "Contact us.",
      "Fill out the contact form below or contact us through the contact information.":
        "Fill out the contact form below or contact us through the contact information.",
      "Follow us on social media": "Follow us on social media",
      "About project": "About project",
      "All rights reserved": "All rights reserved",
      cookieDescription:
        "We use cookies to provide you with the best experience on our website. Information about cookies we use or options to disable cookies can be found in",
      "postavkama kolačića": "cookie settings",
      "Accept cookies": "Accept cookies",
      "Cookie settings": "Cookie settings",
      "Interested in buying?": "Interested in buying?",
      "Take a look at example of the interior of our apartments.":
        "Take a look at example of the interior of our apartments.",
      Interior: "Interior",
      "Look at Living room": "Look at Living room",
      "Look at the Kitchen": "Look at the Kitchen",
      "Modern kitchen with all the necessary appliances":
        "Modern kitchen with all the necessary appliances",
      "Living room with a beautiful view of the sea":
        "Living room with a beautiful view of the sea",
      "Look at the Bedroom": "Look at the Bedroom",
      "Bedroom with a beautiful view of the sea":
        "Bedroom with a beautiful view of the sea",
      "Look at the Bathroom": "Look at the Bathroom",
      "Modern bathroom with all the necessary appliances":
        "Modern bathroom with all the necessary appliances",
      "All apartments": "All apartments",
      "Choose your apartment": "Choose your apartment",
      "Building 1": "Building 1",
      "Building 2": "Building 2",
      "Building 3": "Building 3",
      "Bulding 2 and 3 are on the construction phase, so they are not available for purchase. Building 1 will be automatically selected.":
        "Bulding 2 and 3 are on the construction phase, so they are not available for purchase. Building 1 will be automatically selected.",
      "Ground floor": "Ground floor",
      "First floor": "First floor",
      "Second floor": "Second floor",
      "Apartment S1 offers a modern and spacious living solution with its well-designed layout. Located on the ground floor, this apartment spans an impressive 84.09 square meters, providing ample space for comfortable living. It features a welcoming entrance hallway, a large living area of 27.6 square meters ideal for family gatherings, and two cozy bedrooms measuring 10.9 and 11.4 square meters each. The apartment also includes a 4.5 square meter bathroom, efficiently designed for daily use. Unique to this unit are the external spaces: a covered terrace of 11.3 square meters, an open terrace of 5.1 square meters, and a lush green area of 132.6 square meters, which is perfect for outdoor relaxation or children’s play area. Additionally, the apartment comes with two parking spots, ensuring ample parking for residents and guests. This apartment combines comfort, functionality, and the charm of outdoor living, making it an ideal choice for anyone seeking a serene and convenient lifestyle.":
        "Apartment S1 offers a modern and spacious living solution with its well-designed layout. Located on the ground floor, this apartment spans an impressive 84.09 square meters, providing ample space for comfortable living. It features a welcoming entrance hallway, a large living area of 27.6 square meters ideal for family gatherings, and two cozy bedrooms measuring 10.9 and 11.4 square meters each. The apartment also includes a 4.5 square meter bathroom, efficiently designed for daily use. Unique to this unit are the external spaces: a covered terrace of 11.3 square meters, an open terrace of 5.1 square meters, and a lush green area of 132.6 square meters, which is perfect for outdoor relaxation or children’s play area. Additionally, the apartment comes with two parking spots, ensuring ample parking for residents and guests. This apartment combines comfort, functionality, and the charm of outdoor living, making it an ideal choice for anyone seeking a serene and convenient lifestyle.",
      "Apartment S2 is a ground-floor unit offering an expanse of 86.58 square meters. It features a compact entrance hallway and a substantial living area of 26.2 square meters, perfect for entertaining. The apartment includes two bedrooms, each providing ample space for personal customization. A practical bathroom and a covered terrace of 9.9 square meters add to the comfort, with an additional open terrace of 6.5 square meters. The highlight is the extensive green area of 180.1 square meters, which extends the living space outdoors, perfect for relaxation and family activities. Completing this unit are two dedicated parking spots, enhancing its convenience.":
        "Apartment S2 is a ground-floor unit offering an expanse of 86.58 square meters. It features a compact entrance hallway and a substantial living area of 26.2 square meters, perfect for entertaining. The apartment includes two bedrooms, each providing ample space for personal customization. A practical bathroom and a covered terrace of 9.9 square meters add to the comfort, with an additional open terrace of 6.5 square meters. The highlight is the extensive green area of 180.1 square meters, which extends the living space outdoors, perfect for relaxation and family activities. Completing this unit are two dedicated parking spots, enhancing its convenience.",
      "Located on the first floor, Apartment S3 encompasses 65.55 square meters tailored for efficiency and comfort. The layout includes a welcoming entrance hallway that leads into a vibrant 27.6 square meter living area. The apartment boasts two well-sized bedrooms and a neatly arranged bathroom. The covered terrace of 9 square meters provides a private outdoor retreat. With two parking spots included, this apartment combines practicality with all the essentials of modern living, making it an ideal choice for those seeking a blend of style and functionality.":
        "Located on the first floor, Apartment S3 encompasses 65.55 square meters tailored for efficiency and comfort. The layout includes a welcoming entrance hallway that leads into a vibrant 27.6 square meter living area. The apartment boasts two well-sized bedrooms and a neatly arranged bathroom. The covered terrace of 9 square meters provides a private outdoor retreat. With two parking spots included, this apartment combines practicality with all the essentials of modern living, making it an ideal choice for those seeking a blend of style and functionality.",

      "Apartment S4 offers a unique blend of comfort and practical living on the first floor, covering 63.83 square meters. This apartment includes a seamless flow from the entrance hallway to the living area of 26.2 square meters, suitable for both relaxation and socializing. It features two bedrooms, a functional bathroom, and a covered terrace of 7.7 square meters, creating a cozy extension of the living space. Additionally, two parking spots ensure convenience for residents, making this apartment a practical choice for modern lifestyles.":
        "Apartment S4 offers a unique blend of comfort and practical living on the first floor, covering 63.83 square meters. This apartment includes a seamless flow from the entrance hallway to the living area of 26.2 square meters, suitable for both relaxation and socializing. It features two bedrooms, a functional bathroom, and a covered terrace of 7.7 square meters, creating a cozy extension of the living space. Additionally, two parking spots ensure convenience for residents, making this apartment a practical choice for modern lifestyles.",
      "Apartment with a great view. On the second floor, Apartment S5 spans 65.55 square meters and features a well-designed space that maximizes both comfort and usability. The entrance leads into a large living area of 27.6 square meters, accompanied by two bedrooms, each offering privacy and space. The bathroom is efficiently laid out, and the covered terrace of 9 square meters serves as a serene spot for relaxation. Two parking spots provide added convenience, making this apartment perfectly suited for those seeking a harmonious balance between home comforts and functional living.":
        "Apartment with a great view. On the second floor, Apartment S5 spans 65.55 square meters and features a well-designed space that maximizes both comfort and usability. The entrance leads into a large living area of 27.6 square meters, accompanied by two bedrooms, each offering privacy and space. The bathroom is efficiently laid out, and the covered terrace of 9 square meters serves as a serene spot for relaxation. Two parking spots provide added convenience, making this apartment perfectly suited for those seeking a harmonious balance between home comforts and functional living.",
      "Apartment with a great view. Apartment S6, located on the top floor, encompasses 63.83 square meters of thoughtfully arranged space. This apartment features a straightforward layout with an entrance hallway opening into a 26.2 square meter living area, designed for both daily life and entertaining. It includes two comfortable bedrooms, a well-appointed bathroom, and a covered terrace of 7.7 square meters, offering a quiet outdoor retreat. The inclusion of two parking spots adds to the practicality, making it an excellent choice for individuals or families looking for a modern, convenient living space.":
        "Apartment with a great view. Apartment S6, located on the top floor, encompasses 63.83 square meters of thoughtfully arranged space. This apartment features a straightforward layout with an entrance hallway opening into a 26.2 square meter living area, designed for both daily life and entertaining. It includes two comfortable bedrooms, a well-appointed bathroom, and a covered terrace of 7.7 square meters, offering a quiet outdoor retreat. The inclusion of two parking spots adds to the practicality, making it an excellent choice for individuals or families looking for a modern, convenient living space.",
      "Entrance/Hallway": "Entrance/Hallway",
      "Living area": "Living area",
      "Bedroom 1": "Bedroom 1",
      "Bedroom 2": "Bedroom 2",
      Bedroom: "Bedroom",
      Bathroom: "Bathroom",
      "Covered terrace": "Covered terrace",
      "Open terrace": "Open terrace",
      "Green area": "Green area",
      "Parking spots": "Parking spots",
      "2 parking spots": "2 parking spots",
      Location: "Location",
      "Location of the project": "Location of the project",
      "Near by: ": "Near by ",
      "Bulding 3 is on the construction phase, so it is not available for purchase.":
        "Bulding 3 is on the construction phase, so it is not available for purchase.",
      "Bulding 2 is on the construction phase, so it is not available for purchase.":
        "Bulding 2 is on the construction phase, so it is not available for purchase.",
      Price: "Price",
      "On this page you can see all the available apartments in our buildings.":
        "On this page you can see all the available apartments in our buildings.",
      "All Appartments": "All Appartments",
      "Date builted": "Date builted",
      "Total quadrature": "Total quadrature",
      "December 1, 2023": "December 1, 2023",
      "Pictorial proposal of interior design.":
        "Pictorial proposal of interior design.",
      "Date built Started": "Date built Started",
      "Date built Ends": "Date built Ends",
      "May 1, 2024": "May 1, 2024",
      "June 1, 2025": "June 1, 2025",
      "You are looking for a premium apartment in an attractive location by the sea":
        "You are looking for a premium apartment in an attractive location by the sea",
      "Click here to see 2. apartment on 1. floor":
        "Click here to see 2. apartment on 1. floor",
      "A golf course is planned": "A golf course is planned",
      "Beach Soline": "Beach Soline",
      "Dalmaland Aquapark": "Dalmaland Aquapark",
      "The new residential project in Biograd na moru includes a building with six apartments, which offer a unique living experience. Located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment content, which gives the location an extremely attractive position. The facility is located 10-15 minutes on foot from the three main attractions of Biograd and its surroundings: Soline beach, as the most beautiful beach in this part of Dalmatia, Aquapark Dalmaland, the largest amusement park in all of Dalmatia, and the city center. For the above reasons, the location is ideal for relaxation and entertainment. The building spans two floors and has six apartments, built with top-quality materials, as well as beautiful grounds, ensuring a sense of community and exclusivity. Apartments are available this month at a promotional price of EUR 199,000 for four apartments and EUR 249,000 for two apartments on the ground floor. This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. With a modern design and strategic location, which will become even more attractive over time due to its ideal position.":
        "The new residential project in Biograd na moru includes a building with six apartments, which offer a unique living experience. Located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment content, which gives the location an extremely attractive position. The facility is located 10-15 minutes on foot from the three main attractions of Biograd and its surroundings: Soline beach, as the most beautiful beach in this part of Dalmatia, Aquapark Dalmaland, the largest amusement park in all of Dalmatia, and the city center. For the above reasons, the location is ideal for relaxation and entertainment. The building spans two floors and has six apartments, built with top-quality materials, as well as beautiful grounds, ensuring a sense of community and exclusivity. Apartments are available this month at a promotional price of EUR 199,000 for four apartments and EUR 2.900 EUR/m2 for two apartments on the ground floor. This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. With a modern design and strategic location, which will become even more attractive over time due to its ideal position.",
      "date of possible move-in": "date of possible move-in",
      "On the images, only the furniture projections are displayed, and the apartment comes unfurnished.":
        "On the images, only the furniture projections are displayed, and the apartment comes unfurnished.",
      "Importantly, we offer a complete apartment furnishing service tailored to the owner's preferences and budget, with a turnkey system, price upon agreement.":
        "Importantly, we offer a complete apartment furnishing service tailored to the owner's preferences and budget, with a turnkey system, price upon agreement.",

      "Download English version": "Download English version",
      "Download Croatian version": "Download Croatian version",
      "Promotive offer for this month": "Promotive offer for this month",
      "Click on the apartment to see more details.":
        "Click on the apartment to see more details.",
      "The new residential project in Biograd na moru includes a building with six apartments and offers a unique living experience. It is located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment, which gives the location an extremely attractive position. The facility is located 10-15 minutes on foot from the three main attractions of Biograd and its surroundings: Soline beach, as the most beautiful beach in this part of Dalmatia, Aquapark Dalmaland, the largest amusement park in all of Dalmatia, and the city center. For the above reasons, the location is ideal for relaxation and fun. The building extends over two floors and has six apartments, built of top quality materials, and a beautiful garden, ensuring a sense of community and exclusivity. The apartments are available this month at a promotional price of EUR 199,000 (unit price for an apartment on the upper floors) and EUR 249,000 (unit price for two apartments on the ground floor). This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. We highlight the modern design and strategic location, which will become even more attractive over time due to its ideal position.":
        "The new residential project in Biograd na moru includes a building with six apartments and offers a unique living experience. It is located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment, which makes the location extremely attractive. The building spans two floors and has six apartments, built with premium materials, and a beautiful garden, ensuring a sense of community and exclusivity. The apartments are available this month at a promotional price, starting from 2,900 EUR/m2 for the super attractive ground floors, where there are modern endless terraces opening onto a large courtyard, which is completely fenced from the view from all sides, as well as a wall and a fence. which give a sense of security. A swimming pool, a large barbecue or any other option can be built on them later. Prices on the second floor are 219,000 EUR for an apartment. This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. We emphasize modern design and strategic location, which will become even more attractive over time due to its ideal position.",
      "Construction project of a building with 6 apartments.":
        "Construction project of a building with 6 apartments.",
      "Shopping center": "Shopping center",
      "Show more": "Show more",
      "Show less": "Show less",
      "The new residential project in Biograd na moru includes a building with six apartments and offers a unique living experience. It is located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment, which gives the location an extremely attractive position. The building extends over two floors and has six apartments, built of top quality materials, and a beautiful garden, ensuring a sense of community and exclusivity. The apartments are available this month at a promotional price of EUR 199,000 (unit price for an apartment on the upper floors) and EUR 249,000 (unit price for two apartments on the ground floor). This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. We highlight the modern design and strategic location, which will become even more attractive over time due to its ideal position.":
        "The new residential project in Biograd na moru includes a building with six apartments and offers a unique living experience. It is located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment, which makes the location extremely attractive. The building spans two floors and has six apartments, built with premium materials, and a beautiful garden, ensuring a sense of community and exclusivity. The apartments are available this month at a promotional price, starting from 2,900 EUR/m2 for the super attractive ground floors, where there are modern endless terraces opening onto a large courtyard, which is completely fenced from the view from all sides, as well as a wall and a fence. which give a sense of security. A swimming pool, a large barbecue or any other option can be built on them later. Prices on the second floor are 219,000 EUR for an apartment. This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. We emphasize modern design and strategic location, which will become even more attractive over time due to its ideal position.",
      "Look at our latest news": "Look at our latest news",
      "€ price from": "€ price from",
      "Apartments with a beautiful view.": "Apartments with a beautiful view.",
      "€ price from for apartments (2) on 2. floor.":
        "€ price from for apartments (2) on 2. floor.",
      "All news": "All news",
      "DNEVNI BORAVAK": "LIVING AREA",
      KUHINJA: "KITCHEN",
      KUPAONICA: "BATHROOM",
      TERASA: "TERRACE",
      "SPAVAĆA SOBA": "BEDROOM",
      ULAZ: "ENTRANCE",
      EKSTERIJER: "EXTERIOR",
      OSTALO: "OTHER",
      "Sve novosti": "All news",
      "footer-desc-2":
        "The quality of construction and installation materials is particularly noteworthy. On average, approx. 22,000 EUR+VAT was invested per apartment compared to similar projects on the coast, which is approx. 13% of the value of the property when compared. No Knauf (all brick partitions), no plaster (real cement plastering), stone steps, porcelain tiles inside first-class apartments 120x60 cm (otherwise ceramics are used, which is the level below), air conditioning in every room according to the most modern standards, large three-layer openings (in anthracite color with built-in motors for automatic raising and lowering and ignition at the entrance to the rooms (right next to the light switch), built-in mosquito nets, sliding walls), especially for each kitchen, air outlet from the hood to the top of the roof, chimneys if you want a fireplace, the rear full blanket plus a brick roof with 10 cm of insulation on the rear roofs so that the insulation is top-notch in summer and winter, underfloor heating in the bathroom, external preparation for electric car chargers, illuminated parking, a wall and fence around the entire building, with an additional wall and fence for the ground floor, which especially distinguishes the attractiveness of the ground floor due to privacy from the view and a large yard with an endless terrace, with the possibility of building an 8x4 pool. This includes absolutely complete bathroom equipment according to the highest standards: hansgrohe first category shower and sink faucet, kolpasan shower door, laufen shell, mirror and cabinet",
    },
  },
  hr: {
    translation: {
      Area: "Površina",
      Home: "Početna",
      "About Us": "O nama",
      Appartments: "Apartmani",
      Blog: "Blog",
      Project: "Projekt",
      Contact: "Kontakt",
      Email: "Email",
      "Click to see more": "Kliknite za više",
      "Read more": "Saznajte više",
      Phone: "Telefon",
      News: "Novosti",
      "ENTRANCE/HALLWAY": "ULAZ/HODNIK",
      "LIVING AREA": "DNEVNI BORAVAK",
      KITCHEN: "KUHINJA",
      BATHROOM: "KUPAONICA",
      BEDROOM: "SPAVAĆA SOBA",
      "BEDROOM 1": "SPAVAĆA SOBA 1",
      "BEDROOM 2": "SPAVAĆA SOBA 2",
      "COVERED TERRACE": "NATKRIVENA TERASA",
      "OPEN TERRACE": "OTVORENA TERASA",
      "GREEN AREA": "ZELENA POVRŠINA",
      "2 PARKING SPOTS": "2 PARKIRNA MJESTA",
      "All news": "Sve novosti",
      "€ price from for apartments (2) on 2. floor.":
        "€ cijena od za apartmane (2) na 2. katu.",
      "Apartments with a beautiful view.": "Apartmani s prekrasnim pogledom.",
      "€ price from": "€ cijena od",
      "Look at our latest news": "Pogledajte naše najnovije novosti",
      "Look at news about building process.": "Pogledajte novosti o izgradnji.",
      " Looking for an apartment in an attractive location on the coast?":
        "Tražite apartman na atraktivnoj lokaciji na moru?",
      "Change language": "Change language", // obrnuta logika
      "All appartments": "Svi apartmani",
      "About Project": "O projektu",
      "Looking for an apartment in an attractive location on":
        "Tražite apartman na atraktivnoj lokaciji na",
      "Biograd Riviera": "Biogradskoj rivijeri",
      "Dalmaland Aquapark is a great place for family fun. It is located near Biograd na Moru.":
        "Dalmaland Aquapark je odlično mjesto za obiteljsku zabavu. Nalazi se u blizini Biograda na Moru.",
      "Apartments near the Soline beach are a great choice for a vacation. The beach is almost perfect for the family.":
        "Apartmani u blizini plaže Soline su odličan izbor za odmor. Plaža je gotovo savršena za obitelj.",
      "Biograd na Moru is a city located on the Adriatic Sea. It is known for its rich history and beautiful beaches.":
        "Biograd na Moru je grad smješten na Jadranskom moru. Poznat je po bogatoj povijesti i prekrasnim plažama.",
      "Click here to see 2. apartment on 2. floor":
        "Kliknite ovdje za pregled 2. apartmana na 2. katu",
      "Biograd na Moru": "Biograd na Moru",
      "Click here to see more about Biograd na Moru":
        "Kliknite ovdje za više o Biogradu na Moru",
      "Click here to see 2. apartment on 2. floor":
        "Kliknite ovdje za pregled 2. apartmana na 2. katu",
      "Project of constructing 3 buildings near the sea. Each building has 6 apartments.":
        "Projekt izgradnje 3 zgrade blizu mora. Svaka zgrada ima 6 apartmana.",
      "Our Address": "Naša adresa",
      "Atractive places near the apartments:":
        "Atraktivna mjesta u blizini apartmana:",
      "Town Biograd na Moru": "Grad Biograd na Moru",
      "Dalmaland Aquapark ": "Dalmaland Aquapark ",
      "Soline Beach": "Plaža Soline",
      "Project of constructing 3 buildings near the sea. Each building has 6 apartments.":
        "Projekt izgradnje 3 zgrade blizu mora. Svaka zgrada ima 6 apartmana.",
      "A new residential project in Biograd na Moru features the construction of three buildings, each offering a unique living experience. Situated in the heart of the city, these buildings are conveniently close to the main attractions, including Soline Beach and the Dalmaland Aquapark, making them ideal for both relaxation and entertainment. Each of the three buildings spans three floors and houses six apartments, ensuring a sense of community and exclusivity. The apartments are available at a promotional price of 1999 EUR per square meter, providing an excellent investment opportunity. This project not only offers luxurious living spaces but also benefits from its prime location, blending urban convenience with natural beauty. With their modern design and strategic positioning, these buildings are set to become a coveted address in Biograd na Moru.":
        "Novi stambeni projekt u Biogradu na Moru obuhvaća izgradnju tri zgrade, od kojih svaka nudi jedinstveno iskustvo stanovanja. Smještene u srcu grada, ove zgrade povoljno su blizu glavnih atrakcija, uključujući plažu Soline i Dalmaland Aquapark, što ih čini idealnim za opuštanje i zabavu. Svaka od tri zgrade proteže se na tri kata i ima šest apartmana, osiguravajući osjećaj zajednice i ekskluzivnosti. Apartmani su dostupni po promotivnoj cijeni od 1999 EUR po četvornom metru, pružajući izvrsnu investicijsku priliku. Ovaj projekt ne nudi samo luksuzne stambene prostore, već ima koristi od svoje izvrsne lokacije, spajajući urbano udobnost s prirodnom ljepotom. S modernim dizajnom i strateškim položajem, ove zgrade postat će poželjna adresa u Biogradu na Moru.",
      "The combination of modern design, Middle Dalmatian architecture, and superior construction quality makes this residential building in a peaceful part of Biograd particularly appealing. The property is equally distant (a 10-minute walk) from three key locations: the center of Biograd; Soline, the most beautiful beach in this part of Dalmatia; and Dalmaland, the most attractive amusement/water park in all of Dalmatia.":
        "Kombinacija modernog dizajna, srednjodalmatinske arhitekture i vrhunske kvalitete izrade čini ovaj stambeni objekt u mirnom dijelu Biograda posebno atraktivnim. Objekt je podjednako udaljen (10 minuta pješice) od tri najvažnije lokacije: centra Biograda, Soline - najljepše plaže u ovom dijelu Dalmacije, i Dalmalanda, najatraktivnijeg zabavno-vodenog parka u cijeloj Dalmaciji.",
      "Looking for an apartment in an attractive location on the coast?":
        "Tražite apartman na atraktivnoj lokaciji na moru?",
      "Biograd na Moru is a city located on the Adriatic Sea. It is known for its rich history and beautiful beaches.":
        "Biograd na Moru je grad smješten na Jadranskom moru. Poznat je po bogatoj povijesti i prekrasnim plažama.",
      "Apartments near the Soline beach are a great choice for a vacation. The beach is almost perfect for the family.":
        "Apartmani u blizini plaže Soline su odličan izbor za odmor. Plaža je gotovo savršena za obitelj.",
      "Dalmaland Aquapark is a great place for family fun. It is located near Biograd na Moru.":
        "Dalmaland Aquapark je odlično mjesto za obiteljsku zabavu. Nalazi se u blizini Biograda na Moru.",
      "Beautiful town on the Adriatic Sea": "Lijep grad na Jadranskom moru",
      "The most beautiful beach in Dalmatia": "Najljepša plaža u Dalmaciji",
      "The most attractive amusement/water park in all of Dalmatia":
        "Najatraktivniji zabavno-vodeni park u cijeloj Dalmaciji",
      "Surrounding Places": "Okolna mjesta",
      "Here are some of the most beautiful places in the town. ":
        "Ovdje su neka od najljepših mjesta u gradu.",
      "Follow us on social media": "Pratite nas na društvenim mrežama",
      "€ discount price": "€ cijena sa popustom",
      "Three-room apartments on the first floor.":
        "Trosobni apartmani na prvom katu.",
      "€ for apartments (2) on ground floor.":
        "€ za apartmane (2) u prizemlju.",
      "Attractive garden, ideal for a relaxation zone: installing a swimming pool or jacuzzi.":
        "Atraktivan vrt, idealan za zonu opuštanja: postavljanje bazena ili jacuzzija.",
      " parking spots": " parkirna mjesta",
      "Every apartment has 2 parking spots.":
        "Svaki apartman ima 2 parkirna mjesta.",
      "Click here to see apartments in this building.":
        "Kliknite ovdje za pregled apartmana u ovoj zgradi.",
      "The other two buildings are under construction.":
        "Ostale dvije zgrade su u izgradnji.",
      "Fill out the contact form below or contact us through the contact information.":
        "Ispunite kontakt obrazac ispod ili nas kontaktirajte putem kontaktnih podataka.",
      "Send message": "Pošalji poruku",
      Message: "Poruka",
      "Phone number": "Broj telefona",
      "Last Name": "Prezime",
      "First Name": "Ime",
      "Two Parking Spaces": "Dva parkirna mjesta",
      "Two parking spaces for your car": "Dva parkirna mjesta za vaš automobil",
      "Relax in the jacuzzi in the garden": "Opuštajte se u jacuzziju u vrtu",
      "Jacuzzi in the garden": "Jacuzzi u vrtu",
      "Quiet neighborhood": "Tiho susjedstvo",
      "Great for families": "Odlično za obitelji",
      Exterior: "Eksterijer",
      "Take a look at the exterior of our apartments.":
        "Pogledajte eksterijer naših apartmana.",
      "Take a look at the surrounding places": "Pogledajte okolna mjesta",
      Back: "Povratak",
      "Golf Club in Biograd na Moru": "Golf klub u Biogradu na Moru",
      "Golf Club": "Golf klub",
      "Dalmaland Aquapark ": "Dalmaland Aquapark ",
      "The most attractive amusement/water park in all of Dalmatia":
        "Najatraktivniji zabavno-vodeni park u cijeloj Dalmaciji",
      "The most beautiful beach in Dalmatia": "Najljepša plaža u Dalmaciji",
      "Soline Beach": "Plaža Soline",
      "Beautiful town on the Adriatic Sea": "Lijep grad na Jadranskom moru",
      "Town Biograd na Moru": "Grad Biograd na Moru",
      "Town Biograd na Moru": "Grad Biograd na Moru",
      "Contact us.": "Kontaktirajte nas.",
      "Fill out the contact form below or contact us through the contact information.":
        "Ispunite kontakt obrazac ispod ili nas kontaktirajte putem kontaktnih podataka.",
      "Follow us on social media": "Pratite nas na društvenim mrežama",
      "About project": "O projektu",
      "All rights reserved": "Sva prava pridržana",
      "Contact Us": "Kontaktirajte nas",
      cookieDescription:
        "Koristimo kolačiće kako bismo vam pružili najbolje iskustvo na našoj web stranici. Informacije o kolačićima koje koristimo ili opcije za isključivanje kolačića možete pronaći u",
      "postavkama kolačića": "postavkama kolačića",
      "Accept cookies": "Prihvati",
      "Cookie settings": "Postavke",
      "Interested in buying?": "Zainteresirani za kupnju?",
      "Take a look at example of the interior of our apartments.":
        "Pogledajte primjer unutrašnjosti naših apartmana.",
      Interior: "Interijer",
      "Look at Living room": "Pogledajte dnevni boravak",
      "Look at the Kitchen": "Pogledajte kuhinju",
      "Modern kitchen with all the necessary appliances":
        "Modernu kuhinju sa svim potrebnim aparatima",
      "Living room with a beautiful view of the sea":
        "Dnevni boravak s prekrasnim pogledom na more",
      "Look at the Bedroom": "Pogledajte spavaću sobu",
      "Bedroom with a beautiful view of the sea":
        "Spavaća soba s prekrasnim pogledom na more",
      "Look at the Bathroom": "Pogledajte kupaonicu",
      "Modern bathroom with all the necessary appliances":
        "Moderna kupaonica sa svim potrebnim aparatima",
      "All apartments": "Svi apartmani",
      "Choose your apartment": "Odaberite svoj apartman",
      "Building 1": "Zgrada 1",
      "Building 2": "Zgrada 2",
      "Building 3": "Zgrada 3",
      "Bulding 2 and 3 are on the construction phase, so they are not available for purchase. Building 1 will be automatically selected.":
        "Zgrada 2 i 3 su u fazi izgradnje, pa nisu dostupne za kupnju. Zgrada 1 će biti automatski odabrana.",
      "Ground floor": "Prizemlje",
      "First floor": "Prvi kat",
      "Second floor": "Drugi kat",
      "Apartment S1 offers a modern and spacious living solution with its well-designed layout. Located on the ground floor, this apartment spans an impressive 84.09 square meters, providing ample space for comfortable living. It features a welcoming entrance hallway, a large living area of 27.6 square meters ideal for family gatherings, and two cozy bedrooms measuring 10.9 and 11.4 square meters each. The apartment also includes a 4.5 square meter bathroom, efficiently designed for daily use. Unique to this unit are the external spaces: a covered terrace of 11.3 square meters, an open terrace of 5.1 square meters, and a lush green area of 132.6 square meters, which is perfect for outdoor relaxation or children’s play area. Additionally, the apartment comes with two parking spots, ensuring ample parking for residents and guests. This apartment combines comfort, functionality, and the charm of outdoor living, making it an ideal choice for anyone seeking a serene and convenient lifestyle.":
        "Apartman S1 nudi moderno i prostrano rješenje za život s dobro osmišljenim rasporedom. Smješten na prizemlju, ovaj apartman prostire se na impresivnih 84,09 četvornih metara, pružajući dovoljno prostora za udoban život. Ima dobrodošli ulazni hodnik, veliki dnevni boravak od 27,6 četvornih metara idealan za obiteljska okupljanja i dvije udobne spavaće sobe od 10,9 i 11,4 četvornih metara svaka. Apartman također uključuje kupaonicu od 4,5 četvornih metara, učinkovito osmišljenu za svakodnevnu upotrebu. Jedinstveni za ovu jedinicu su vanjski prostori: natkrivena terasa od 11,3 četvornih metara, otvorena terasa od 5,1 četvornih metara i bujno zeleno područje od 132,6 četvornih metara, koje je savršeno za vanjsko opuštanje ili dječje igralište. Osim toga, apartman dolazi s dva parkirna mjesta, osiguravajući dovoljno parkirnih mjesta za stanare i goste. Ovaj apartman kombinira udobnost, funkcionalnost i šarm vanjskog života, čineći ga idealnim izborom za sve koji traže miran i praktičan način života.",
      "Apartment S2 is a ground-floor unit offering an expanse of 86.58 square meters. It features a compact entrance hallway and a substantial living area of 26.2 square meters, perfect for entertaining. The apartment includes two bedrooms, each providing ample space for personal customization. A practical bathroom and a covered terrace of 9.9 square meters add to the comfort, with an additional open terrace of 6.5 square meters. The highlight is the extensive green area of 180.1 square meters, which extends the living space outdoors, perfect for relaxation and family activities. Completing this unit are two dedicated parking spots, enhancing its convenience.":
        "Apartman S2 je jedinica u prizemlju koja nudi prostranstvo od 86,58 četvornih metara. Ima kompaktni ulazni hodnik i značajnu dnevnu sobu od 26,2 četvorna metra, savršenu za zabavu. Apartman uključuje dvije spavaće sobe, svaka pružajući dovoljno prostora za osobnu prilagodbu. Praktična kupaonica i natkrivena terasa od 9,9 četvornih metara dodaju udobnost, s dodatnom otvorenom terasom od 6,5 četvornih metara. Glavna značajka je prostrano zeleno područje od 180,1 četvornih metara, koje proširuje prostor za život na otvorenom, savršeno za opuštanje i obiteljske aktivnosti. Ovoj jedinici dodijeljena su dva parkirna mjesta, poboljšavajući njenu praktičnost.",
      "Located on the first floor, Apartment S3 encompasses 65.55 square meters tailored for efficiency and comfort. The layout includes a welcoming entrance hallway that leads into a vibrant 27.6 square meter living area. The apartment boasts two well-sized bedrooms and a neatly arranged bathroom. The covered terrace of 9 square meters provides a private outdoor retreat. With two parking spots included, this apartment combines practicality with all the essentials of modern living, making it an ideal choice for those seeking a blend of style and functionality.":
        "Smješten na prvom katu, apartman S3 obuhvaća 65,55 četvornih metara prilagođenih učinkovitosti i udobnosti. Raspored uključuje dobrodošli ulazni hodnik koji vodi u živahnu dnevnu sobu od 27,6 četvornih metara. Apartman ima dvije dobro dimenzionirane spavaće sobe i uredno uređenu kupaonicu. Natkrivena terasa od 9 četvornih metara pruža privatni vanjski bijeg. S dva parkirna mjesta uključena, ovaj apartman kombinira praktičnost sa svim bitnim elementima suvremenog života, čineći ga idealnim izborom za one koji traže spoj stila i funkcionalnosti.",
      "Apartment S4 offers a unique blend of comfort and practical living on the first floor, covering 63.83 square meters. This apartment includes a seamless flow from the entrance hallway to the living area of 26.2 square meters, suitable for both relaxation and socializing. It features two bedrooms, a functional bathroom, and a covered terrace of 7.7 square meters, creating a cozy extension of the living space. Additionally, two parking spots ensure convenience for residents, making this apartment a practical choice for modern lifestyles.":
        "Apartman S4 nudi jedinstvenu kombinaciju udobnosti i praktičnog života na prvom katu, s površinom od 63,83 četvornih metara. Ovaj apartman uključuje besprijekoran protok od ulaznog hodnika do dnevnog boravka od 26,2 četvorna metra, pogodan za opuštanje i druženje. Ima dvije spavaće sobe, funkcionalnu kupaonicu i natkrivenu terasu od 7,7 četvornih metara, stvarajući ugodno proširenje prostora za život. Osim toga, dva parkirna mjesta osiguravaju praktičnost za stanare, čineći ovaj apartman praktičnim izborom za moderne životne stilove.",
      "Apartment with a great view. On the second floor, Apartment S5 spans 65.55 square meters and features a well-designed space that maximizes both comfort and usability. The entrance leads into a large living area of 27.6 square meters, accompanied by two bedrooms, each offering privacy and space. The bathroom is efficiently laid out, and the covered terrace of 9 square meters serves as a serene spot for relaxation. Two parking spots provide added convenience, making this apartment perfectly suited for those seeking a harmonious balance between home comforts and functional living.":
        "Stan s odličnim pogledom. Na drugom katu, apartman S5 prostire se na 65,55 četvornih metara i ima dobro osmišljen prostor koji maksimizira udobnost i upotrebljivost. Ulaz vodi u veliki dnevni boravak od 27,6 četvornih metara, praćen s dvije spavaće sobe, svaka pružajući privatnost i prostor. Kupaonica je učinkovito postavljena, a natkrivena terasa od 9 četvornih metara služi kao mirno mjesto za opuštanje. Dva parkirna mjesta pružaju dodatnu praktičnost, čineći ovaj apartman savršeno prikladnim za one koji traže harmoničnu ravnotežu između kućnih udobnosti i funkcionalnog života.",
      "Apartment with a great view. Apartment S6, located on the top floor, encompasses 63.83 square meters of thoughtfully arranged space. This apartment features a straightforward layout with an entrance hallway opening into a 26.2 square meter living area, designed for both daily life and entertaining. It includes two comfortable bedrooms, a well-appointed bathroom, and a covered terrace of 7.7 square meters, offering a quiet outdoor retreat. The inclusion of two parking spots adds to the practicality, making it an excellent choice for individuals or families looking for a modern, convenient living space.":
        "Stan s odličnim pogledom. Apartman S6, smješten na vrhu zgrade, obuhvaća 63,83 četvornih metara pažljivo osmišljenog prostora. Ovaj apartman ima jednostavan raspored s ulaznim hodnikom koji se otvara u dnevni boravak od 26,2 četvorna metra, dizajniran za svakodnevni život i zabavu. Uključuje dvije udobne spavaće sobe, dobro opremljenu kupaonicu i natkrivenu terasu od 7,7 četvornih metara, koja nudi mirno vanjsko utočište. Uključivanje dva parkirna mjesta dodaje praktičnost, čineći ga izvrsnim izborom za pojedince ili obitelji koje traže moderan, praktičan životni prostor.",
      "Entrance/Hallway": "Ulaz/Hodnik",
      "Living area": "Dnevni boravak",
      Bathroom: "Kupaonica",
      "Bedroom 1": "Spavaća soba 1",
      "Bedroom 2": "Spavaća soba 2",
      Bedroom: "Spavaća soba",
      "Covered terrace": "Natkrivena terasa",
      "Open terrace": "Otvorena terasa",
      "Green area": "Zelena površina",
      "2 parking spots": "2 parkirna mjesta",
      Location: "Lokacija",
      "Location of the project": "Lokacija projekta",
      "Near by: ": "U blizini ",
      "Bulding 2 is on the construction phase, so it is not available for purchase.":
        "Zgrada 2 je u fazi izgradnje, pa nije dostupna za kupnju.",
      "Bulding 3 is on the construction phase, so it is not available for purchase.":
        "Zgrada 3 je u fazi izgradnje, pa nije dostupna za kupnju.",
      Price: "Cijena",
      "On this page you can see all the available apartments in our buildings.":
        "Na ovoj stranici možete vidjeti sve dostupne apartmane u našim zgradama.",
      "All Appartments": "Svi apartmani",
      "Date builted": "Datum izgradnje",
      "Total quadrature": "Ukupna kvadratura",
      "December 1, 2023": "1. Prosinca 2023.",
      "Pictorial proposal of interior design.":
        "Vizualni prijedlog unutarnjeg uređenja.",
      "Date built Started": "Datum početka izgradnje",
      "Date built Ends": "Datum završetka izgradnje",
      "May 1, 2024": "1. Svibnja 2024.",
      "June 1, 2025": "1. Lipnja 2025.",
      "Our apartments.": "Naši apartmani.",
      "Dalmaland Aquapark": "Dalmaland Aquapark",
      "Soline Beach": "Plaža Soline",
      "Town Centre": "Centar grada",
      "You are looking for a premium apartment in an attractive location by the sea":
        "Tražite premium apartman na atraktivnoj lokaciji na moru",
      "Click here to see 2. apartment on 1. floor":
        "Kliknite ovdje za pregled 2. apartmana na 1. katu",
      "A golf course is planned": "Golf igralište je u planu",
      "Beach Soline": "Plaža Soline",
      "Dalmaland Aquapark": "Dalmaland Aquapark",
      "The new residential project in Biograd na moru includes a building with six apartments, which offer a unique living experience. Located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment content, which gives the location an extremely attractive position. The facility is located 10-15 minutes on foot from the three main attractions of Biograd and its surroundings: Soline beach, as the most beautiful beach in this part of Dalmatia, Aquapark Dalmaland, the largest amusement park in all of Dalmatia, and the city center. For the above reasons, the location is ideal for relaxation and entertainment. The building spans two floors and has six apartments, built with top-quality materials, as well as beautiful grounds, ensuring a sense of community and exclusivity. Apartments are available this month at a promotional price of EUR 199,000 for four apartments and EUR 249,000 for two apartments on the ground floor. This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. With a modern design and strategic location, which will become even more attractive over time due to its ideal position.":
        "Novi stanbeni projekat u Biogradu na moru, obuhvaća zgradu sa šest stanova, koji nude jedinstveno iskustvo stanovanja. Smještena na mirnom dijelu grada, na samoj ivici zelene površine, koje su planirane za zabavni sadržaj, što lokaciji daje izuzetno atraktivan položaj. Objekt je smješten na 10-15 minuta pješice od glavne tri atrakcije Biograda I okolice: plaza Soline, kao najljepša plaza u ovom dijelu Dalmacije, Aquapark Dalmaland, najveći zabani park u cijeloj Dalmaciji I centar grada. Lokacija je iz navedenih razloga idealna za opuštanje I zabavu. Zgrada se proteže na dva kata I ima šest stanova, građenih od vrhunskih materijala, kao i s lijepom okućnisom, osiguravajući osjećaj zajednice I ekskluzivnosti. Apartmani su dostupni ovaj mjesec po promocijskoj cijeni od 199.000 EUR za četiri apartmana I 2.900 EUR/m2 za dva apartmana u prizemlju. Ovaj projekt ne nudi samo luksuzne stambene prostore, već ima koristi od svoje izvrsne lokacije, spajajući urbano udobnost s prirodnom ljepotom. S modernim dizajnom i strateškim položajem, koji će s vremenom biti još atraktivniji zbog svoje idealne pozicije.",
      "date of possible move-in": "datum mogućeg useljenja",
      "On the images, only the furniture projections are displayed, and the apartment comes unfurnished.":
        "Na slikama su prikazane samo projekcije namještaja, a stan dolazi nenamješten.",
      "Importantly, we offer a complete apartment furnishing service tailored to the owner's preferences and budget, with a turnkey system, price upon agreement.":
        "Važno je napomenuti da nudimo uslugu potpunog namještanja stana prilagođenu vlasnikovim željama i budžetu, po sistemu ključ u ruke, cijena po dogovoru.",
      "Download English version": "Preuzmi englesku verziju",
      "Download Croatian version": "Preuzmi Hrvatsku verziju",
      "Promotive offer for this month": "Promotivna ponuda za ovaj mjesec",
      "Click on the apartment to see more details.":
        "Kliknite na apartman za više detalja.",
      "The new residential project in Biograd na moru includes a building with six apartments and offers a unique living experience. It is located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment, which gives the location an extremely attractive position. The facility is located 10-15 minutes on foot from the three main attractions of Biograd and its surroundings: Soline beach, as the most beautiful beach in this part of Dalmatia, Aquapark Dalmaland, the largest amusement park in all of Dalmatia, and the city center. For the above reasons, the location is ideal for relaxation and fun. The building extends over two floors and has six apartments, built of top quality materials, and a beautiful garden, ensuring a sense of community and exclusivity. The apartments are available this month at a promotional price of EUR 199,000 (unit price for an apartment on the upper floors) and EUR 249,000 (unit price for two apartments on the ground floor). This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. We highlight the modern design and strategic location, which will become even more attractive over time due to its ideal position.":
        "Novi stambeni projekt u Biogradu na moru obuhvaća zgradu sa šest stanova i nudi jedinstveno iskustvo stanovanja. Smješten je u mirnom dijelu grada, na samoj ivici zelenih površina, koje su planirane za zabavu, što lokaciju čini izuzetno atraktivnom. Zgrada se proteže na dva kata i ima šest stanova, izgrađenih od vrhunskih materijala, te prekrasan vrt, osiguravajući osjećaj zajednice i ekskluzivnosti. Apartmani su dostupni ovog mjeseca po promotivnoj cijeni, počevši od 2.900 EUR/m2 za superatraktivna prizemlja, gdje su modern ‘’endless’’ terase koje izlaze na veliko dvorište, koje je komplatno ograđeno od pogleda sa svih strana, kao i zida, te ograde koji daju osjećaj sigurnosti. Na istima se može naknadno neometano izgraditi bazen, veliki roštilj ili bilo koja druga opcija. Cijene na drugom katu su 219.000 EUR za stan. Ovaj projekt ne nudi samo luksuzne stambene prostore, već ima koristi od svoje izvrsne lokacije, spajajući urbano udobnost s prirodnom ljepotom. Ističemo moderan dizajn i stratešku lokaciju, koja će s vremenom postati još atraktivnija zbog svoje idealne pozicije.",
      "Construction project of a building with 6 apartments.":
        "Projekt izgradnje zgrade sa 6 apartmana.",
      "Shopping center": "Trgovački centar",
      "The new residential project in Biograd na moru includes a building with six apartments and offers a unique living experience. It is located in a quiet part of the city, on the very edge of green areas, which are planned for entertainment, which gives the location an extremely attractive position. The building extends over two floors and has six apartments, built of top quality materials, and a beautiful garden, ensuring a sense of community and exclusivity. The apartments are available this month at a promotional price of EUR 199,000 (unit price for an apartment on the upper floors) and EUR 249,000 (unit price for two apartments on the ground floor). This project not only offers luxurious living spaces, but also benefits from its excellent location, combining urban comfort with natural beauty. We highlight the modern design and strategic location, which will become even more attractive over time due to its ideal position.":
        "Novi stambeni projekt u Biogradu na moru obuhvaća zgradu sa šest stanova i nudi jedinstveno iskustvo stanovanja. Smješten je u mirnom dijelu grada, na samoj ivici zelenih površina, koje su planirane za zabavu, što lokaciju čini izuzetno atraktivnom. Zgrada se proteže na dva kata i ima šest stanova, izgrađenih od vrhunskih materijala, te prekrasan vrt, osiguravajući osjećaj zajednice i ekskluzivnosti. Apartmani su dostupni ovog mjeseca po promotivnoj cijeni, počevši od 2.900 EUR/m2 za superatraktivna prizemlja, gdje su modern ‘’endless’’ terase koje izlaze na veliko dvorište, koje je komplatno ograđeno od pogleda sa svih strana, kao i zida, te ograde koji daju osjećaj sigurnosti. Na istima se može naknadno neometano izgraditi bazen, veliki roštilj ili bilo koja druga opcija. Cijene na drugom katu su 219.000 EUR za stan. Ovaj projekt ne nudi samo luksuzne stambene prostore, već ima koristi od svoje izvrsne lokacije, spajajući urbano udobnost s prirodnom ljepotom. Ističemo moderan dizajn i stratešku lokaciju, koja će s vremenom postati još atraktivnija zbog svoje idealne pozicije.",
      "Show more": "Prikaži više",
      "Show less": "Prikaži manje",
      "DNEVNI BORAVAK": "DNEVNI BORAVAK",
      KUHINJA: "KUHINJA",
      "SPAVAĆA SOBA": "SPAVAĆA SOBA",
      KUPAONICA: "KUPAONICA",
      "SPAVAĆA SOBA": "SPAVAĆA SOBA",
      TERASA: "TERASA",
      ULAZ: "ULAZ",
      OSTALO: "OSTALO",
      "footer-desc-2":
        "Posebno se ističe kvaliteta gradnje i ugradbenih materijala. U prosjeku po stanu je uloženo cca 22.000 EUR+PDV u odnosu na slične projekte na obali, što je cca 13% vrijednosti nekretnine kad se ide u usporedbu. Bez knaufa (sve ciglene pregrade), bez gipsa (pravo cementno žbukanje), kamene stepenice, porculanske pločice unutar stanova prve klase 120x60 cm (inače se koristi keramika što je nivo ispod) klima u svakoj prostoriji po najsuvremenijim standardima, veliki troslojni otvori (u antracit boji s ugrađenim motorima za automatsko dizanje i spuštanje i paljenje uz ulaz u prostorije (odmah uz prekidač za svjetlo), ugrađeni komarnici, klizne stjenke), posebno za svaku kuhinju odvod zraka iz nape na vrh krova, dimnjaci u slučaju želje za kaminom, zadnja puna deka plus ciglani krov s 10 cm izolacije po zadnjoj deci kako bi izolacija bila vrhunska ljeti i zimi, podno grijanje u kupaonici, vanjska priprema za punjače auta na struju, osvjetljen parking, zid i ograda oko cijelog objekta, uz dodatni zid i ogradu za prizemlje, što posebno izdvaja atraktivnost prizemlja zbog privatnosti od pogleda i velikim dvorištem s ''endless'' terasom, uz mogućnost izgradnje bazena 8x4. Na ovo ide i apsolutno kompletna oprema kupaonice po najvišim standardima: hansgrohe tuš prve kategorije i česma za lavabo, kolpasan vrata tuša, laufen školjka, ogledalo i ormarić",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "hr",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
