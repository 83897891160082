import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import slika from "../images/katalog-slike/zgrade.png";
import backgroundImg from "../images/katalog-slike/zgrade-s-pozadinom-2.png";
import ApartmentCard from "../components/ApartmentCard";
import "../styles/Apartments.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import PDFLinks from "../components/PDFLinks";
import { serverUrl } from "../utils/domainName";
import { useAppContext } from "../AppContext";
import LoadingSpinner from "../components/LoadingSpinner";

const Appartments2 = () => {
  const { apartments, setApartments } = useAppContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1700 });
    fetchApartments();
  }, []);

  const fetchApartments = () => {
    fetch(`${serverUrl}api/apartments`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setApartments(data);
        setLoading(false);
      })
      .catch((err) => {
        fetchApartments();
      });
  };

  return (
    <main className="aparments-page page">
      <section className="sub-hero-ver-1">
        <img src={backgroundImg} alt="Zgrade" className="background-img" />
        <h1
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          {t("Look at all Appartments")}
          <span> {`(${apartments.length})`}</span>
        </h1>
        <p
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="300"
          data-aos-offset="0"
        >
          {t(
            "On this page you can see all the available apartments in our buildings."
          )}
        </p>
        <img src={slika} alt="Zgrade" className="img" />
      </section>
      <section className="building">
        <h1 data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
          {t("Building 1")}
        </h1>
        <div className="apartments">
          {loading && <LoadingSpinner />}
          {apartments.map((apartment, index) => (
            <ApartmentCard key={index} apartment={apartment} />
          ))}
        </div>
      </section>{" "}
      <section className="building">
        <h1 data-aos="fade-up" data-aos-duration="500">
          {t("Building 2")}
        </h1>
        <h3 data-aos="fade-up" data-aos-duration="500">
          {t(
            "Bulding 2 is on the construction phase, so it is not available for purchase."
          )}
        </h3>
      </section>{" "}
      <section className="building">
        <h1 data-aos="fade-up" data-aos-duration="500">
          {t("Building 3")}
        </h1>
        <h3 data-aos="fade-up" data-aos-duration="500">
          {t(
            "Bulding 3 is on the construction phase, so it is not available for purchase."
          )}
        </h3>
      </section>
      <PDFLinks />
    </main>
  );
};

export default Appartments2;
