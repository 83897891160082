import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import AllNews from "./pages/AllNews";
import Location from "./pages/Location";
import Contact from "./pages/Contact";
import Cookies from "./components/Cookies";
import { useTranslation } from "react-i18next";
import "./i18n"; // osigurava da je i18n konfiguriran
import LinkNavigator from "./components/LinkNavigator";
import Project from "./pages/Project";
import Appartments from "./pages/Appartments";
import Appartment from "./pages/Appartment";
import { AppProvider } from "./AppContext";
import News from "./pages/News";

function App() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <AppProvider>
      <BrowserRouter>
        <Cookies />
        <Header />
        <LinkNavigator />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/location" element={<Location />} />
          <Route path="/appartments" element={<Appartments />} />
          <Route path="/project" element={<Project />} />
          <Route path="/appartments/:slug" element={<Appartment />} />
          <Route path="/news" element={<AllNews />} />{" "}
          <Route path="/news/:slug" element={<News />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
