import React, { useState } from "react";
import "../styles/Select.scss";

const Select = ({ onChange, optionsJSX, values }) => {
  const [selected, setSelected] = useState(values[0]);
  const [open, setOpen] = useState(false);

  return (
    <div className="select">
      <button
        className="close"
        style={!open ? { display: "none" } : { display: "block" }}
        onClick={() => setOpen(!open)}
      ></button>
      <div className="selected" onClick={() => setOpen(!open)}>
        {optionsJSX[values.indexOf(selected)]}
      </div>
      {open && (
        <ul className="options">
          {optionsJSX.map((option, index) => (
            <li
              key={index}
              onClick={() => {
                setSelected(values[index]);
                onChange({ target: { value: values[index] } });
                setOpen(false);
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Select;
