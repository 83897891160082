import React, { useState } from "react";
import slika from "../images/katalog-slike/interijer-2.png";
import { useTranslation } from "react-i18next";
import facebook from "../images/socials/facebook-white.svg";
import instagram from "../images/socials/instagram-white.svg";
import whatsUpp from "../images/socials/whatsupp-white.svg";
import "../styles/ContactSection.scss";

const ContactSection = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submit action

    // Check if required fields are filled
    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.message.trim()
    ) {
      alert("Please fill in all required fields.");
      return; // Stop the function if validation fails
    }

    try {
      const response = await fetch(
        "https://mail-server-gold.vercel.app/send-email/mp-premium",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) throw new Error("Network response was not ok.");

      const result = await response.json();
      console.log("Server Response:", result);
      alert("Message sent successfully!");
      setFormData({ name: "", surname: "", email: "", phone: "", message: "" }); // Reset form after submission
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message.");
    }
  };

  return (
    <section className="contact-section">
      <div className="contact-image">
        <img src={slika} alt="Contact" />
      </div>
      <form
        className="contact-form"
        onSubmit={handleSubmit}
        data-aos="fade-up"
        data-aos-duration="300"
      >
        <h3>{t("Interested in buying?")}</h3>
        <h2>{t("Contact us.")}</h2>
        <p>
          {t(
            "Fill out the contact form below or contact us through the contact information."
          )}
        </p>{" "}
        <div className="contact-info">
          <div className="contact-info-item">
            {" "}
            <div className="contact-info-item-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
              </svg>
              <div className="pulsating-circle"></div>{" "}
              <div className="pulsating-circle"></div>
            </div>
            <p>info@mp-premium.com</p>
          </div>
          <div className="contact-info-item">
            {" "}
            <div className="contact-info-item-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="pulsating-circle"></div>{" "}
              <div className="pulsating-circle"></div>
            </div>
            <p>+385 91 947 5006</p>
          </div>
        </div>
        <div className="contact-form-inputs">
          <div className="contact-form-input-row">
            <input
              type="text"
              name="name"
              placeholder={t("First Name")}
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="surname"
              placeholder={t("Last Name")}
              value={formData.surname}
              onChange={handleChange}
            />
          </div>
          <div className="contact-form-input-row">
            <input
              type="text"
              name="email"
              placeholder={t("E-mail")}
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="phone"
              placeholder={t("Phone number")}
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <textarea
            name="message"
            placeholder={t("Message")}
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" className="btn">
            {t("Send message")}
          </button>
        </div>
        <div className="social-networks">
          <h4>{t("Follow us on social media")}:</h4>
          <div className="social-networks-links">
            <a href="https://www.facebook.com/MPPremiumRealEstate/">
              <img src={facebook} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/mp_premium_real_estate/">
              <img src={instagram} alt="Instagram" />
            </a>
            {/*
            <a href="https://www.whatsapp.com">
              <img src={whatsUpp} alt="WhatsUpp" />
            </a>
            */}
          </div>
        </div>
      </form>
    </section>
  );
};

export default ContactSection;
