import React, { useEffect, useState } from "react";
import "../styles/Cookies.scss";
import { useTranslation } from "react-i18next"; // Uvoz useTranslation
import { Link } from "react-router-dom";
import logo from "../images/mp-premium-logo.png";

const Cookies = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [cookiesSettings, setCookiesSettings] = useState(false);
  const { t } = useTranslation(); // Dohvaćanje t funkcije za prijevod

  useEffect(() => {
    if (sessionStorage.getItem("cookiesAccepted")) {
      setCookiesAccepted(true);
    }
  }, []);

  const handleCookiesAccept = () => {
    setCookiesAccepted(true);
    sessionStorage.setItem("cookiesAccepted", true);
  };

  return (
    <>
      <section className={`cookies ${cookiesAccepted ? "cookies-hidden" : ""}`}>
        <p className="cookies-description">
          {t("cookieDescription")}{" "}
          <span href="/cookies-settings" className="cookies-link">
            {t("postavkama kolačića")}
          </span>
          .
        </p>
        <div className="cookies-buttons">
          <button
            className="cookies-button cookies-accept"
            onClick={handleCookiesAccept}
          >
            <span>{t("Accept cookies")} </span>🍪
          </button>
          <button
            className="cookies-button cookies-settings"
            onClick={() => setCookiesSettings(true)}
          >
            <span>{t("Cookie settings")} </span>
          </button>
        </div>
      </section>
      <section
        className={`cookies-settings-section ${
          cookiesSettings ? "active" : ""
        }`}
      >
        <div className="logo">
          <img src={logo} alt="MP Premium logo" />
        </div>
        <div className="info-text">
          <h3>{t("We respect your privacy")}</h3>
          <p> {t("cookieDescription")}</p>
        </div>
        <div className="cookies-list">
          <button
            className="cookies-button cookies-accept"
            onClick={() => setCookiesSettings(true)}
          >
            {t("Accept all cookies")}
          </button>
          <h3>{t("Configure accepted cookies")}</h3>
          <div className="cookies-list-table">
            <div className="cookies-list-row">
              <span>{t("Essential cookies")}</span>
              <h5>{t("Accepted")}</h5>
            </div>
          </div>
        </div>
        <button
          className="close"
          onClick={() => setCookiesSettings(false)}
        ></button>
        <div className="bottom">
          <p>{t("© 2024 MP Premium Real Estate d.o.o.")} </p>
        </div>
      </section>
    </>
  );
};

export default Cookies;
