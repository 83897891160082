import React, { useEffect, useState } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import "../styles/MapPoint.scss";
import { useTranslation } from "react-i18next";
import plazaSolineSlika from "../images/hero/plaza-soline.png";
import biogradCentarSlika from "../images/hero/biograd.jpg";
import dalmalandSlika from "../images/hero/dalmaland.jpg";
import slikaApartmana from "../images/katalog-slike/zgrada-1.png";
import shoppingCentar from "../images/hero/shopping-centar.jpg";
const MapPoint = () => {
  const { t } = useTranslation();
  let apiKey = "AIzaSyDe07jdWY3XT3XlacF0OUOJyKY0SbaHt4I";

  const [showInfoBoxes, setShowInfoBoxes] = useState([
    true,
    true,
    true,
    true,
    true,
  ]);

  // 43.93256403507078, 15.463863301246546
  let positionApartmant = {
    lat: 43.93256403507078,
    lng: 15.463863301246546,
  };

  // 43.93174136948819, 15.478809996913226
  let positionDalmalandAquapark = {
    lat: 43.93174136948819,
    lng: 15.478809996913226,
  };

  // 43.930268713125834, 15.451147454671016
  let positionPlazaSoline = {
    lat: 43.930268713125834,
    lng: 15.451147454671016,
  };

  // 43.937047291039676, 15.44260781528384
  let positionCentarBiogradNaMoru = {
    lat: 43.937047291039676,
    lng: 15.44260781528384,
  };

  // 43.94190827692907, 15.452988352739212
  let positionShoppingCentar = {
    lat: 43.94190827692907,
    lng: 15.452988352739212,
  };

  return (
    <APIProvider apiKey={apiKey}>
      <section className="map-container">
        <Map
          mapId={"264befe9355da411"}
          zoom={
            window.innerWidth < 768
              ? 13.2
              : window.innerWidth < 1024
              ? 13.7
              : window.innerWidth < 1440
              ? 14.5
              : 15
          }
          options={{ draggable: true }}
          defaultCenter={positionApartmant}
        >
          <AdvancedMarker position={positionApartmant}>
            <Pin />
            {showInfoBoxes[0] && (
              <InfoWindow
                position={positionApartmant}
                offset={[0, -40]}
                onClose={() => setShowInfoBoxes([false, true, true, true])}
              >
                <div className="info-box-map">
                  <div className="image">
                    <img src={slikaApartmana} alt="Slika apartmana" />
                  </div>
                  <p>{t("Our apartments.")} </p>
                </div>
              </InfoWindow>
            )}
          </AdvancedMarker>

          <AdvancedMarker position={positionDalmalandAquapark}>
            <Pin />
            {showInfoBoxes[1] && (
              <InfoWindow
                position={positionDalmalandAquapark}
                offset={[0, -40]}
                onClose={() => setShowInfoBoxes([true, false, true, true])}
              >
                <div className="info-box-map">
                  <div className="image">
                    <img src={dalmalandSlika} alt="Dalmaland" />
                  </div>
                  <p>{t("Dalmaland Aquapark")}</p>
                </div>
              </InfoWindow>
            )}
          </AdvancedMarker>

          <AdvancedMarker position={positionPlazaSoline}>
            <Pin />
            {showInfoBoxes[2] && (
              <InfoWindow
                position={positionPlazaSoline}
                offset={[0, -40]}
                onClose={() => setShowInfoBoxes([true, true, false, true])}
              >
                <div className="info-box-map">
                  <div className="image">
                    <img src={plazaSolineSlika} alt="Plaža Soline" />
                  </div>
                  <p>{t("Soline Beach")}</p>
                </div>
              </InfoWindow>
            )}
          </AdvancedMarker>

          <AdvancedMarker position={positionCentarBiogradNaMoru}>
            <Pin />
            {showInfoBoxes[3] && (
              <InfoWindow
                position={positionCentarBiogradNaMoru}
                offset={[0, -40]}
                onClose={() => setShowInfoBoxes([true, true, true, false])}
              >
                <div className="info-box-map">
                  <div className="image">
                    <img src={biogradCentarSlika} alt="Centar Biograda" />
                  </div>
                  <p>{t("Town Centre")}</p>
                </div>
              </InfoWindow>
            )}
          </AdvancedMarker>

          <AdvancedMarker position={positionShoppingCentar}>
            <Pin />
            {showInfoBoxes[3] && (
              <InfoWindow
                position={positionShoppingCentar}
                offset={[0, -40]}
                onClose={() => setShowInfoBoxes([true, true, true, false])}
              >
                <div className="info-box-map">
                  <div className="image">
                    <img src={shoppingCentar} alt="Centar Biograda" />
                  </div>
                  <p>{t("Shopping center")}</p>
                </div>
              </InfoWindow>
            )}
          </AdvancedMarker>
        </Map>
      </section>
    </APIProvider>
  );
};

export default MapPoint;
