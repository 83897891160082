import React from "react";
import { useTranslation } from "react-i18next";
import MapPoint from "./MapPoint";
import "../styles/LocationSection.scss";

const LocationSection = () => {
  const { t } = useTranslation();
  return (
    <section className="location-section">
      <h1 data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
        {t("Location of the project")}
      </h1>
      <h4 data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
        Biograd na Moru
      </h4>
      <h5 data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
        Put Grande
      </h5>
      <div
        className="location-section-map"
        data-aos="fade-up"
        data-aos-duration="500"
      >
        <MapPoint />
      </div>
    </section>
  );
};

export default LocationSection;
