import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Uvoz useTranslation
import "../styles/BuildingsChooser.scss";
import building from "../images/katalog-slike/zgrada-1.png";
import buildings from "../images/katalog-slike/zgrade.png";
import { Link } from "react-router-dom";

const BuildingsChooser = () => {
  const { t } = useTranslation(); // Dohvaćanje t funkcije za prijevod
  const [buildingsOrBuilding, setBuildingsOrBuilding] = useState("buildings");
  const [choosenBuilding, setChoosenBuilding] = useState();
  const [showInfoBoxes, setShowInfoBoxes] = useState([false, false]);
  useEffect(() => {
    // show info boxes after 2 seconds
    const t1 = setTimeout(() => {
      const t2 = setTimeout(() => {
        setShowInfoBoxes([true, true]);
      }, 500);
      setShowInfoBoxes([true, false]); // show info boxes after 2 seconds

      return () => clearTimeout(t2);
    }, 500);
    return () => clearTimeout(t1);
  }, []);
  return (
    <>
      <div
        className={
          buildingsOrBuilding === "buildings"
            ? "buildings-chooser-buildings active"
            : "buildings-chooser-buildings "
        }
      >
        <img src={buildings} alt="Zgrada" />{" "}
        <button className="apartment-pin unactive"></button>
        <button className="apartment-pin unactive">
          <div
            className="info-box"
            style={{ display: showInfoBoxes[1] ? "flex" : "none" }}
          >
            <div className="triangle"></div>
            <span>{t("The other two buildings are under construction.")}</span>
          </div>
        </button>
        <button
          className="apartment-pin "
          onClick={() => {
            setChoosenBuilding(1);
            setBuildingsOrBuilding("building");
            console.log("choosen building", choosenBuilding);
          }}
        >
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>{" "}
          <div
            className="info-box"
            style={{ display: showInfoBoxes[1] ? "flex" : "none" }}
          >
            <div className="triangle"></div>
            <span>{t("Click here to see apartments in this building.")}</span>
          </div>
        </button>
      </div>

      <div
        className={
          buildingsOrBuilding === "buildings"
            ? "buildings-chooser-building"
            : "buildings-chooser-building active"
        }
      >
        <img src={building} alt="Zgrada" />
        <Link className="apartment-pin" to={`/appartments/apartment-s1`}>
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
        </Link>
        <Link className="apartment-pin" to={`/appartments/apartman-s5`}>
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>{" "}
          <div
            className="info-box"
            style={{ display: showInfoBoxes[1] ? "flex" : "none" }}
          >
            <div className="triangle"></div>
            <span>{t("Click here to see 2. apartment on 2. floor")}</span>
          </div>
        </Link>
        <Link className="apartment-pin" to={`/appartments/apartment-s3`}>
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
        </Link>
        <Link className="apartment-pin" to={`/appartments/apartment-s2`}>
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
        </Link>
        <Link className="apartment-pin" to={`/appartments/apartment-s6`}>
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
        </Link>
        <Link className="apartment-pin" to={`/appartments/apartment-s4`}>
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
          <div
            className="info-box"
            style={{ display: showInfoBoxes[0] ? "flex" : "none" }}
          >
            <div className="triangle"></div>
            <span>{t("Click here to see 2. apartment on 1. floor")}</span>
          </div>
        </Link>
      </div>
    </>
  );
};

export default BuildingsChooser;
