import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../styles/ApartmentCard.scss";

const ApartmentCard = ({ key, apartment }) => {
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  function truncateText(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }

    return str.slice(0, str.lastIndexOf(" ", maxLength)) + "...";
  }

  const calculateQuadratures = () => {
    let quadratures = 0;
    apartment.quadraturesPerRoom.forEach((quadrature) => {
      quadratures += quadrature.quadrature * quadrature.coeficient;
    });
    // round to 2 decimal places
    return quadratures.toFixed(2);
  };

  return (
    <Link
      className="apartment-card"
      key={key}
      to={`/appartments/${apartment.slug}`}
    >
      <div className="apartment-card-image background-image-loading">
        <img src={apartment.images[0].url} alt={apartment.title.en + key} />
      </div>
      <div className="apartment-card-info">
        <Link
          className="btn-rounded black"
          to={`/appartments/${apartment.slug}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </Link>

        <h3 className="appartment-card-title">
          {language === "hr" ? apartment.title.hr : apartment.title.en}
        </h3>
        <h4>
          {apartment.floor === 0
            ? t("Ground floor")
            : apartment.floor === 1
            ? t("First floor")
            : t("Second floor")}
        </h4>
        <p className="apartment-description">
          {truncateText(
            language === "hr"
              ? apartment.description.hr
              : apartment.description.en,
            120
          )}
        </p>
        <div className="apartment-card-bottom">
          <div className="apartment-card-bottom-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>{" "}
            <p>
              {apartment.price === 2900
                ? apartment.price.toLocaleString("de-DE") + " €/m2"
                : apartment.price !== 1
                ? apartment.price.toLocaleString("de-DE") + " €"
                : ""}
            </p>
          </div>
          <div className="apartment-card-bottom-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3"
              />
            </svg>

            <p>{calculateQuadratures()} m²</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ApartmentCard;
