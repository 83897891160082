import React, { useEffect } from "react";
import ContactSection from "../components/ContactSection";
import Aos from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1700 });
  }, []);
  return (
    <main className="contact-page page">
      <ContactSection />
    </main>
  );
};

export default Contact;
