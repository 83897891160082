import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import slika1 from "../images/katalog-slike/interijer-1.png";
import slika2 from "../images/katalog-slike/interijer-4.png";
import slika3 from "../images/katalog-slike/interijer-6.png";
import slika4 from "../images/katalog-slike/interijer-8.png";
import "../styles/Interrier.scss";

const Interrier = () => {
  const { t } = useTranslation();

  const [active, setActive] = useState(0);

  const [interrier, setInterrier] = useState([
    {
      title: "Look at Living room",
      description: "Living room with a beautiful view of the sea",
      image: slika1,
    },

    {
      title: "Look at the Kitchen",
      description: "Modern kitchen with all the necessary appliances",
      image: slika2,
    },

    {
      title: "Look at the Bedroom",
      description: "Bedroom with a beautiful view of the sea",
      image: slika3,
    },

    {
      title: "Look at the Bathroom",
      description: "Modern bathroom with all the necessary appliances",
      image: slika4,
    },
  ]);

  return (
    <section className="interrier">
      <div className="interrier-info">
        <h2 data-aos="fade-right" data-aos-duration="500">
          {t("Interior")}
        </h2>
        <p data-aos="fade-right" data-aos-duration="500">
          {t("Pictorial proposal of interior design.")}
        </p>

        <div className="interrier-info-items">
          {interrier.map((interrier, index) => (
            <button
              key={index}
              className="interrier-info-item"
              onMouseEnter={() => setActive(index)}
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-delay={index * 100}
            >
              <h4>
                {t(interrier.title)}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                  />
                </svg>
              </h4>
              <p>{t(interrier.description)}.</p>
            </button>
          ))}
        </div>
      </div>
      <div className="interrier-image">
        <img
          src={slika1}
          alt="Interrier"
          className={active === 0 ? "active" : ""}
        />{" "}
        <img
          src={slika2}
          alt="Interrier"
          className={active === 1 ? "active" : ""}
        />{" "}
        <img
          src={slika3}
          alt="Interrier"
          className={active === 2 ? "active" : ""}
        />{" "}
        <img
          src={slika4}
          alt="Interrier"
          className={active === 3 ? "active" : ""}
        />
      </div>
    </section>
  );
};

export default Interrier;
