import React, { useEffect, useState } from "react";
import LocationSection from "../components/LocationSection";
import SurroundingPlaces from "../components/SurroundingPlaces";
import { useTranslation } from "react-i18next";
import slika1 from "../images/hero/biograd.jpg";
import slika2 from "../images/hero/plaza-soline.jpg";
import slika3 from "../images/hero/dalmaland.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

const Location = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1700 });
  }, []);

  const [activeBackground, setActiveBackground] = useState(0);
  const [backgrounds, setBackgrounds] = useState([slika1, slika2, slika3]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveBackground((activeBackground + 1) % backgrounds.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeBackground, backgrounds]);

  return (
    <main className="location-page page">
      {" "}
      <section className="sub-hero-ver-2">
        <img
          src={backgrounds[activeBackground]}
          alt="Zgrade"
          className="background-img"
        />
        <h1
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          {t("Near by: ")}: {t("Town Centre")}, {t("Dalmaland Aquapark ")} &{" "}
          {t("Soline Beach")}
        </h1>
        <p
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          {t(
            "The combination of modern design, Middle Dalmatian architecture, and superior construction quality makes this residential building in a peaceful part of Biograd particularly appealing. The property is equally distant (a 10-minute walk) from three key locations: the center of Biograd; Soline, the most beautiful beach in this part of Dalmatia; and Dalmaland, the most attractive amusement/water park in all of Dalmatia."
          )}
        </p>
      </section>
      <LocationSection />
      <SurroundingPlaces />
    </main>
  );
};

export default Location;
