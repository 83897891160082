import React, { useEffect, useState } from "react";
import "../styles/ChooseApartment.scss";
import { Link } from "react-router-dom";
import building from "../images/katalog-slike/zgrada-1.png";
import { useTranslation } from "react-i18next"; // Uvoz useTranslation
import { useNavigate } from "react-router-dom";
import { serverUrl } from "../utils/domainName";
import { useAppContext } from "../AppContext";
import LoadingSpinner from "./LoadingSpinner";

const ChooseApartment = () => {
  const { t } = useTranslation(); // Dohvaćanje t funkcije za prijevod
  const [choosenApartment, setChoosenApartment] = useState(5);
  const [hoveredApartment, setHoveredApartment] = useState(0);
  const navigate = useNavigate();
  const { apartments, setApartments } = useAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchApartments();
  }, []);

  const fetchApartments = () => {
    fetch(`${serverUrl}api/apartments`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        setApartments(data);
        setLoading(false);
      })
      .catch((err) => {
        fetchApartments();
      });
  };

  function truncateText(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }

    return str.slice(0, str.lastIndexOf(" ", maxLength)) + "...";
  }

  let width = window.innerWidth;

  const handleNavigation = (path) => {
    navigate(path);
  };

  const calculateQuadratures = (appartment) => {
    if (!appartment) return 0;

    let quadratures = 0;
    appartment.quadraturesPerRoom.forEach((quadrature) => {
      quadratures += quadrature.quadrature * quadrature.coeficient;
    });
    // round to 2 decimal places
    return quadratures.toFixed(2);
  };

  const formatDate = (date) => {
    const dateObj = new Date(date);

    // replace the / with . in the date string
    const dateString = dateObj.toLocaleDateString().replace(/\//g, ".");
    return dateString + ".";
  };

  return (
    <section
      className="choose-apartment"
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-delay="0"
    >
      <div className="choose-apartment-info">
        <h1>{t("Choose your apartment")}</h1>

        <div className="choose-building">
          {apartments?.map((apartment, index) => (
            <button
              className={hoveredApartment === index ? "active" : ""}
              onClick={() => {
                setChoosenApartment(index);
                setHoveredApartment(index);
              }}
              onMouseEnter={() => setHoveredApartment(index)}
              onMouseLeave={() => setHoveredApartment(choosenApartment)}
              key={index + "apartment-button" + apartment.slug}
            >
              {apartment.title.hr}
            </button>
          ))}
        </div>
        <p>{t("Click on the apartment to see more details.")}</p>

        {loading && <LoadingSpinner />}

        {apartments.map((apartment, index) => (
          <div
            className={`apartment-info ${
              index === choosenApartment ? "active" : ""
            }`}
            key={index + "apartment" + apartment.slug}
          >
            <h2>{apartment.title.hr}</h2>
            <div className="apprtment-floor-and-price">
              <h4>
                {apartment.floor === 0
                  ? t("Ground floor")
                  : apartment.floor === 1
                  ? t("First floor")
                  : t("Second floor")}
              </h4>
              <h3>
                {apartment?.price === 2900
                  ? apartment?.price.toLocaleString("de-DE") + " €/m2"
                  : apartment?.price !== 1
                  ? apartment?.price.toLocaleString("de-DE") + " €"
                  : ""}
              </h3>
            </div>
            <div className="apartment-quadratures">
              {apartment.quadraturesPerRoom.map((room, roomIndex) => (
                <p key={room.id + roomIndex}>
                  <span>{t(room.room)}</span>: {room.quadrature}m²
                </p>
              ))}
            </div>
            <p className="apartment-description">
              {truncateText(t(apartment.description.hr), 200)}
            </p>
            <div className="apartment-date-and-total-quadratures">
              <div className="apartment-date-and-total-quadratures-item">
                <h5>{calculateQuadratures(apartment)}m²</h5>
                <h6> {t("Total quadrature")} </h6>
              </div>
              <div className="apartment-date-and-total-quadratures-item">
                <h5>{formatDate(apartment?.dateBuiltStarted)}</h5>
                <h6>{t("Date built Started")} </h6>
              </div>
              <div className="apartment-date-and-total-quadratures-item">
                <h5>{formatDate(apartment?.dateBuiltEnds)}</h5>
                <h6>{t("date of possible move-in")} </h6>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Link className="btn-rounded black" to="/appartments">
        {t("All apartments")}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
          />
        </svg>
      </Link>
      <div className={"choose-apartment-building"}>
        <img src={building} alt="Zgrada" />
        <button
          className={
            choosenApartment === 0 ? "apartment-pin active" : "apartment-pin"
          }
          onClick={() => {
            if (width < 1300) {
              if (apartments[0]?.slug) {
                handleNavigation("/appartments/" + apartments[0]?.slug);
              } else {
              }
            } else {
              setChoosenApartment(0);
            }
          }}
        >
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
        </button>
        <button
          className={
            choosenApartment === 4 ? "apartment-pin active" : "apartment-pin"
          }
          onClick={() => {
            if (width < 1300) {
              if (apartments[4]?.slug) {
                handleNavigation("/appartments/" + apartments[4]?.slug);
              } else {
              }
            } else {
              setChoosenApartment(4);
            }
          }}
        >
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>{" "}
          <div className="info-box">
            <div className="triangle"></div>
            <span>{t("Click here to see 2. apartment on 2. floor")}</span>
          </div>
        </button>
        <button
          className={
            choosenApartment === 2 ? "apartment-pin active" : "apartment-pin"
          }
          onClick={() => {
            if (width < 1300) {
              if (apartments[2]?.slug) {
                handleNavigation("/appartments/" + apartments[2]?.slug);
              } else {
              }
            } else {
              setChoosenApartment(2);
            }
          }}
        >
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
        </button>
        <button
          className={
            choosenApartment === 1 ? "apartment-pin active" : "apartment-pin"
          }
          onClick={() => {
            if (width < 1300) {
              if (apartments[1]?.slug) {
                handleNavigation("/appartments/" + apartments[1]?.slug);
              } else {
              }
            } else {
              setChoosenApartment(1);
            }
          }}
        >
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
        </button>
        <button
          className={
            choosenApartment === 5 ? "apartment-pin active" : "apartment-pin"
          }
          onClick={() => {
            if (width < 1300) {
              if (apartments[5]?.slug) {
                handleNavigation("/appartments/" + apartments[5]?.slug);
              } else {
              }
            } else {
              setChoosenApartment(5);
            }
          }}
        >
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
        </button>
        <button
          className={
            choosenApartment === 3 ? "apartment-pin active" : "apartment-pin"
          }
          onClick={() => {
            if (width < 1300) {
              if (apartments[3]?.slug) {
                handleNavigation("/appartments/" + apartments[3]?.slug);
              } else {
              }
            } else {
              setChoosenApartment(3);
            }
          }}
        >
          <div className="circle"></div>
          <div className="pulsating-circle"></div>{" "}
          <div className="pulsating-circle"></div>
          <div className="info-box not-mobile">
            <div className="triangle"></div>
            <span>{t("Click here to see 2. apartment on 2. floor")}</span>
          </div>
        </button>
      </div>
    </section>
  );
};

export default ChooseApartment;
