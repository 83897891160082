import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import slika from "../images/katalog-slike/zgrade.png";
import backgroundImg from "../images/katalog-slike/zgrade-s-pozadinom-2.png";
import AboutProjectSection from "../components/AboutProjectSection";
import ChooseApartment from "../components/ChooseApartment";
import LocationSection from "../components/LocationSection";
import Aos from "aos";
import "aos/dist/aos.css";
import PDFLinks from "../components/PDFLinks";

const Project = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1700 });
  }, []);
  return (
    <div className="project-page page">
      <section className="sub-hero-ver-1">
        <img src={backgroundImg} alt="Zgrade" className="background-img" />
        <h1
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          {t("About Project")}
        </h1>
        <p
          data-aos="fade-right"
          data-aos-duration="500"
          data-aos-delay="0"
          data-aos-offset="0"
        >
          {t(
            "The combination of modern design, Middle Dalmatian architecture, and superior construction quality makes this residential building in a peaceful part of Biograd particularly appealing. The property is equally distant (a 10-minute walk) from three key locations: the center of Biograd; Soline, the most beautiful beach in this part of Dalmatia; and Dalmaland, the most attractive amusement/water park in all of Dalmatia."
          )}
        </p>
        <img src={slika} alt="Zgrade" className="img" />
      </section>
      <AboutProjectSection />
      <ChooseApartment />
      <LocationSection />
      <PDFLinks />
    </div>
  );
};

export default Project;
