import React from "react";
import { useTranslation } from "react-i18next";
import NewsCard from "./NewsCard";
import { Link } from "react-router-dom";
import "../styles/NewsSection.scss";
import LoadingSpinner from "./LoadingSpinner";

const NewsSection = ({ news, loading }) => {
  const { t } = useTranslation();
  return (
    <section className="news-section">
      <div className="news-section-header">
        <h2>{t("News")}</h2>
        <p>{t("Look at news about building process.")}</p>
        <Link to="/news" className="btn-rounded black">
          {t("All news")}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
            />
          </svg>
        </Link>
      </div>{" "}
      <div className="news-container">
        {" "}
        {loading && <LoadingSpinner />}
        {news
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3)
          .map((n, index) => (
            <NewsCard key={index + "news-card"} news={n} />
          ))}
      </div>
    </section>
  );
};

export default NewsSection;
