import React, { useState, useEffect, useRef } from "react";
import "../styles/ImagesScroll.scss";

const ImagesScroll = ({ images }) => {
  const imagesContainerRef = useRef(null);
  const scrollAmount = window.innerWidth * 0.5; // 50% of the viewport width

  const scrollRight = () => {
    if (imagesContainerRef.current) {
      imagesContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const scrollLeft = () => {
    if (imagesContainerRef.current) {
      imagesContainerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <section
        className="images-scroll background-image-loading"
        ref={imagesContainerRef}
      >
        {" "}
        <div className="images-scroll-container">
          {images?.map((image, index) => (
            <img src={image.url} alt={index + "appartment-image"} key={index} />
          ))}
        </div>
      </section>
      <button
        className="scroll-right"
        onClick={() => scrollRight()}
        data-aos="fade-left"
        data-aos-duration="200"
        data-aos-delay="0"
        data-aos-offset="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.3}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
      <button
        className="scroll-left"
        onClick={() => scrollLeft()}
        data-aos="fade-right"
        data-aos-duration="200"
        data-aos-delay="0"
        data-aos-offset="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.3}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5 8.25 12l7.5-7.5"
          />
        </svg>
      </button>
    </>
  );
};

export default ImagesScroll;
