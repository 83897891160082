import React from "react";
import "../styles/RoomSection.scss";
import { useTranslation } from "react-i18next";

const RoomSection = ({ title, images, subtitle, description, theme }) => {
  const { t } = useTranslation();
  return (
    <section className={`room-section ${theme}`}>
      <h1>{t(title)}</h1>
      <h3>{t(subtitle)}</h3>
      <p>{t(description)}</p>
      <div className="room-images">
        {images.map((image, index) => (
          <div className="room-images-container" key={index}>
            <img src={image.url} alt={title + index} />{" "}
          </div>
        ))}
      </div>
    </section>
  );
};

export default RoomSection;
