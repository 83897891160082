import React from "react";
import "../styles/Eksterrier.scss";
import PlacesCarousel from "./PlacesCarousel";
import { useTranslation } from "react-i18next"; // Uvoz useTranslation

const Eksterrier = () => {
  const { t } = useTranslation(); // Dohvaćanje t funkcije za prijevod
  return (
    <section className="exterior" data-aos="fade-up" data-aos-duration="500">
      <h1>{t("Exterior")}</h1>
      <p> {t("Take a look at the exterior of our apartments.")}</p>
      <PlacesCarousel />
    </section>
  );
};

export default Eksterrier;
