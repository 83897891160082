import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Uvoz useTranslation
import "../styles/Header.scss";
import english from "../images/flags/english.webp";
import croatian from "../images/flags/croatian.png";
import { changeLanguage } from "i18next";
import logo from "../images/mp-premium-logo.png";
import Select from "./Select";
import facebook from "../images/socials/facebook-white.svg";
import instagram from "../images/socials/instagram-white.svg";
import whatsUpp from "../images/socials/whatsupp-white.svg";

const Header = () => {
  const { t } = useTranslation(); // Dohvaćanje t funkcije za prijevod
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const optionsJSX = [
    <>
      <img src={english} alt="English flag" />
      <span>English</span>
    </>,
    <>
      <img src={croatian} alt="Croatian flag" />
      <span>Hrvatski</span>
    </>,
    ,
  ];

  const values = ["en", "hr"];

  return (
    <>
      <header>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="MP Premium logo" />
          </Link>
        </div>
        <div className="header-container">
          <section className="header-information">
            <div className="header-information-contact">
              <a href="mailto:info@mp-premium.com">
                {t("Email")}: <span>info@mp-premium.com</span>
              </a>
              <a href="tel:+385919475006">
                {t("Phone")}: <span>+385 91 947 5006</span>
              </a>
            </div>

            <div className="header-information-language-change">
              <p>{t("Change language")}:</p>
              <Select
                onChange={(e) => changeLanguage(e.target.value)}
                optionsJSX={optionsJSX}
                values={values}
              />
            </div>
          </section>
          <nav className="navbar">
            <ul className="links">
              <li className="link">
                <NavLink to="/" activeClassName="active">
                  {t("Home")}
                </NavLink>
              </li>
              <li className="link">
                <NavLink to="/project" activeClassName="active">
                  {t("Project")}
                </NavLink>
              </li>
              <li className="link">
                <NavLink to="/appartments" activeClassName="active">
                  {t("Appartments")}
                </NavLink>
              </li>
              <li className="link">
                <NavLink to="/location" activeClassName="active">
                  {t("Location")}
                </NavLink>
              </li>{" "}
              <li className="link">
                <NavLink to="/news" activeClassName="active">
                  {t("News")}
                </NavLink>
              </li>{" "}
            </ul>

            <NavLink className="btn contact-us" to="/contact-us">
              {t("Contact")}
            </NavLink>
            <button
              className="menu-btn"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </nav>
        </div>
      </header>
      <nav className={`mobile__menu ${showMobileMenu ? "show" : ""}`}>
        <div className="top">
          <Link className="logo" to="/">
            <img src={logo} alt="logo" />
          </Link>{" "}
        </div>
        <div className="info">
          <a href="mailto:info@mp-premium.com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>

            <span>info@mp-premium.com</span>
          </a>
        </div>

        <div className="links">
          <li className="link">
            <NavLink
              to="/"
              activeClassName="active"
              onClick={() => setShowMobileMenu(false)}
            >
              {t("Home")}
            </NavLink>
          </li>{" "}
          <li className="link">
            <NavLink
              to="/project"
              activeClassName="active"
              onClick={() => setShowMobileMenu(false)}
            >
              {t("Project")}
            </NavLink>
          </li>
          <li className="link">
            <NavLink
              to="/appartments"
              activeClassName="active"
              onClick={() => setShowMobileMenu(false)}
            >
              {t("Appartments")}
            </NavLink>
          </li>
          <li className="link">
            <NavLink
              to="/location"
              activeClassName="active"
              onClick={() => setShowMobileMenu(false)}
            >
              {t("Location")}
            </NavLink>
          </li>{" "}
          <li className="link">
            <NavLink
              to="/news"
              activeClassName="active"
              onClick={() => setShowMobileMenu(false)}
            >
              {t("News")}
            </NavLink>
          </li>{" "}
        </div>

        <div className="bottom">
          <div className="socials">
            <a
              className="social"
              href="https://www.facebook.com/MPPremiumRealEstate/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={facebook} alt="facebook_logo" />
            </a>{" "}
            {/*
            <a
              className="social"
              href="https://www.instagram.com/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={whatsUpp} alt="instagram_logo" />
            </a>{" "}
            */}
            <a
              className="social"
              href="https://www.instagram.com/mp_premium_real_estate/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={instagram} alt="instagram_logo" />
            </a>
          </div>
          <div className="rights">
            <p>{t("© 2024 MP Premium Real Estate d.o.o.")} </p>
          </div>
        </div>

        <button
          className={showMobileMenu ? "close__btn show" : "close__btn"}
          onClick={() => setShowMobileMenu(false)}
        ></button>
      </nav>
    </>
  );
};

export default Header;
