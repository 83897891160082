import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/PlacesCarousel.scss";
import slika1 from "../images/eksterijer/eksterijer-1.png";
import slika2 from "../images/eksterijer/eksterijer-2.png";
import slika3 from "../images/eksterijer/eksterijer-3.png";

import { useTranslation } from "react-i18next"; // Uvoz useTranslation

const PlacesCarousel = () => {
  const { t } = useTranslation(); // Dohvaćanje t funkcije za prijevod

  const [places, setPlaces] = useState([
    {
      title: "Jacuzzi in the garden",
      type: "Relax in the jacuzzi in the garden",
      image: slika1,
    },
    {
      title: "Quiet neighborhood",
      type: "Great for families",
      image: slika3,
    },
    {
      title: "Two Parking Spaces",
      type: "Two parking spaces for your car",
      image: slika2,
    },
  ]);

  const [currentPlace, setCurrentPlace] = useState(
    places?.length === 0 ? 0 : 1
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentPlace === places?.length - 1) {
        setCurrentPlace(0);
      } else {
        setCurrentPlace(currentPlace + 1);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [currentPlace]);

  const getTranslateValue = () => {
    const offset = -70; // Assuming each slide takes up 70vw
    return `${15 + currentPlace * offset}vw`;
  };

  return (
    <div className="places__carousel__container">
      <div
        className="places__carousel"
        style={{ transform: `translateX(${getTranslateValue()})` }}
      >
        {places?.map((place, index) => (
          <div
            className={index === currentPlace ? "place active" : "place"}
            key={index}
          >
            <div className="place__image">
              <img src={place?.image} alt={place?.title} />
            </div>
            <div className="place__title">
              <h3>{t(place?.title)}</h3>
              <h5>{t(place?.type)}</h5>
            </div>
          </div>
        ))}
      </div>

      <button
        className="places__carousel__button__left"
        onClick={() => {
          if (currentPlace === 0) {
            setCurrentPlace(places?.length - 1);
          } else {
            setCurrentPlace(currentPlace - 1);
          }
        }}
      ></button>

      <button
        className="places__carousel__button__right"
        onClick={() => {
          if (currentPlace === places?.length - 1) {
            setCurrentPlace(0);
          } else {
            setCurrentPlace(currentPlace + 1);
          }
        }}
      ></button>

      <div className="places__carousel__buttons">
        {places?.map((place, index) => (
          <button
            key={index}
            onClick={() => setCurrentPlace(index)}
            className={index === currentPlace ? " active" : ""}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default PlacesCarousel;
