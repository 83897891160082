import React, { useState } from "react";
import slika1 from "../images/hero/biograd.jpg";
import slika2 from "../images/hero/plaza-soline.png";
import slika3 from "../images/hero/dalmaland.jpg";
import slika4 from "../images/hero/golf.jpg";
import "../styles/SurroundingPlaces.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SurroundingPlaces = () => {
  const { t } = useTranslation();

  const [active, setActive] = useState(0);
  const [places, setPlaces] = useState([
    {
      title: "Town Centre",
      type: "Beautiful town on the Adriatic Sea",
      image: slika1,
      url: "/surrounding-places/town-biograd",
    },
    {
      title: "Soline Beach",
      type: "The most beautiful beach in Dalmatia",
      image: slika2,
      url: "/surrounding-places/beach-soline",
    },
    {
      title: "Dalmaland Aquapark ",
      type: "The most attractive amusement/water park in all of Dalmatia",
      image: slika3,
      url: "/surrounding-places/dalmaland",
    },
    {
      title: "A golf course is planned",
      type: "A golf course is planned",
      image: slika4,
      url: "/surrounding-places/golf-club",
    },
  ]);
  return (
    <section className="surrounding-places">
      <h1 data-aos="fade-up" data-aos-duration="500" data-aos-delay="0">
        {" "}
        {t("Surrounding Places")}{" "}
      </h1>
      <p data-aos="fade-up" data-aos-duration="500" data-aos-delay="0">
        {" "}
        {t("Take a look at the surrounding places")}{" "}
      </p>
      <div
        className="surrounding-places-container"
        data-aos="fade-up"
        data-aos-duration="500"
      >
        {places.map((place, index) => (
          <div
            key={index}
            className={
              active === index
                ? "surrounding-place active"
                : "surrounding-place"
            }
            onMouseEnter={() => setActive(index)}
          >
            <img src={place.image} alt={place.title} />
            <div className="surrounding-place-info">
              <h3>{t(place.title)}</h3>
              <p>{t(place.type)}.</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SurroundingPlaces;
