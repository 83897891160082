import React from "react";
import pdfEnglishVersion from "../pdf/Katalog_stanova_print_en.pdf";
import pdfCroatianVersion from "../pdf/Katalog_stanova_print_hr.pdf";
import { useTranslation } from "react-i18next";

const PDFLinks = () => {
  const { t } = useTranslation();
  return (
    <div className="interrier-proposal-information">
      <h1>
        {t(
          "Download the brochure and choose the apartment that suits you best."
        )}
      </h1>
      <h3>
        {t(
          "You can download Croatian and English versions of the brochure by clicking on the links below."
        )}
      </h3>

      <div className="pdf-links">
        <a
          className="btn"
          href={pdfCroatianVersion}
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
          {t("Download Croatian version")}
        </a>
        <a
          className="btn"
          href={pdfEnglishVersion}
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
          {t("Download English version")}
        </a>
      </div>
    </div>
  );
};

export default PDFLinks;
