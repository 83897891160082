import React, { useState, useEffect } from "react";
import "../styles/LinkNavigator.scss";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LinkNavigator = () => {
  const { t } = useTranslation(); // Dohvaćanje t funkcije za prijevod
  const { pathname } = useLocation();
  console.log(pathname);
  const [isDisplay, setIsDisplay] = useState(false);
  const currentPageUrlArray = pathname.split("/");

  // split the url into an array of strings

  const getLink = (item) => {
    //get where the link should lead to
    let link = "/";

    for (let i = 1; i < currentPageUrlArray.length; i++) {
      if (i <= item) {
        link += currentPageUrlArray[i] + "/";
      }
    }

    return link;
  };

  useEffect(() => {
    if (
      currentPageUrlArray.length >= 3 ||
      currentPageUrlArray[1] === "contact-us" ||
      currentPageUrlArray[1] === "project" ||
      currentPageUrlArray[1] === "blog" ||
      currentPageUrlArray[1] === "surraunding-places" ||
      currentPageUrlArray[1] === "appartments" ||
      currentPageUrlArray[1] === "location" ||
      currentPageUrlArray[1] === "news"
    ) {
      setIsDisplay(true);
    } else {
      setIsDisplay(false);
    }
  }, [currentPageUrlArray]);

  return (
    <div
      className="link__navigator"
      style={{ display: isDisplay ? "" : "none" }}
    >
      <button className="back__navigator" onClick={() => window.history.back()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>

        <p>{t("Back")}</p>
      </button>

      <div className="link__navigator__items">
        <Link to={"/" + currentPageUrlArray[0]}>{t("Home")}</Link>

        {
          // if the url is longer than 2 strings, display the rest of the links
          currentPageUrlArray.length >= 2 &&
            currentPageUrlArray.map((item, index) => {
              if (index >= 1) {
                return (
                  <>
                    <div
                      className="link__navigator__items__divider"
                      key={item + "divider"}
                    >
                      /
                    </div>
                    <Link to={`${getLink(index)}`} key={item + index}>
                      {t(
                        item
                          .replace(/-/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())
                      )}
                    </Link>
                  </>
                );
              }
            })
        }
      </div>
    </div>
  );
};

export default LinkNavigator;
